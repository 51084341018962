import {Injectable} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    constructor(
        private media: MediaMatcher
    ) {}

    getMaxWidthQuery(widthPx: number) {
        return this.media.matchMedia(`(max-width: ${widthPx}px)`);
    }
}
