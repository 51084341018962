import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ConsultantAppointmentRequestData} from "../../../generated/data";
import {FormBuilder} from "@angular/forms";
import {
  ConsultantAppointmentResource,
  ConsultantTalentProfilePictureResource,
} from "../../../generated/resources";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {TalentProfileDialogComponent} from "../../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component";
import {environment} from "../../../environments/environment";
import {ConfirmDialogComponent} from "../../dialog-sheets/confirm-dialog/confirm-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'appointment-requests',
  templateUrl: './appointment-requests.component.html',
  styleUrls: ['./appointment-requests.component.scss']
})
export class AppointmentRequestsComponent implements OnInit {

  public displayedColumns: string[] = ['picture', 'lastName', 'firstName', 'assignDate', 'appointmentDate', 'actions'];
  public loading: boolean = true;

  finishedIds: number[] = []
  loadingFinish: boolean = false

  requestData: ConsultantAppointmentRequestData[] = []

  constructor(
      private fb: FormBuilder,
      private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource,
      private dialogService: DialogService,
      private consultantAppointmentResource: ConsultantAppointmentResource,
      private snackBar: MatSnackBar,
      private changeDetectorRefs: ChangeDetectorRef

  ) {
  }

  ngOnInit(): void {
    this.getRequests();
  }

  getRequests(): void {
    this.loading = true;

    this.consultantAppointmentResource.getOpenConsultantAppointmentRequests().then(requests => {
      this.requestData = requests
      this.getTalentImageUrls();
      this.loading = false;
    })
  }

  viewTalentProfile(talentId: number) {
    const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
    comp.instance.talentId = talentId;

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      subscription.unsubscribe();
    });
  }

  getTalentImageUrls() {
    this.requestData.forEach(data => {
      if (data.talent.profilePictureId) {
        this.getTalentProfilePictureHash(data.talent.id).then(imageHash => {
          data['imgUrl'] = 'url(' + environment.apiUrl + `/consultant/talents/${data.talent.id}/profilePicture/${imageHash})`
        })
      }
      else data['imgUrl'] = 'url(../assets/images/blurred_user.png)'
    })
  }

  getTalentProfilePictureHash(talentId: number): Promise<string> {
    return this.consultantTalentProfilePictureResource.getProfileImageHash(talentId)
  }

  handleRequestProceeded(funcName: string, snackBarMsg: string, dialogResult: boolean, id: number) {
    if (dialogResult) {
      this.consultantAppointmentResource[funcName]({requestId: id}).then( () => {
            this.snackBar.open(snackBarMsg, null, {duration: 3000})
            let data = [...this.requestData]
            data.splice(data.findIndex(r => r.requestId == id), 1)
            this.requestData = data
            this.loadingFinish = false
          }
      )
    } else {
      this.finishedIds.splice(this.finishedIds.indexOf(id), 1)
      this.loadingFinish = false
    }
  }

  // handleRequestProceeded(func: (requestParams: {requestId: number}) => Promise<any>, snackBarMsg: string, dialogResult: boolean, id: number) {
  //   if (dialogResult) {
  //     func({requestId: id}).then( () => {
  //           this.snackBar.open(snackBarMsg, null, {duration: 3000})
  //           let data = [...this.requestData]
  //           data.splice(data.findIndex(r => r.requestId == id), 1)
  //           this.requestData = data
  //           this.loadingFinish = false
  //         }
  //     )
  //   } else {
  //     this.finishedIds.splice(this.finishedIds.indexOf(id), 1)
  //     this.loadingFinish = false
  //   }
  // }

  denyRequest(id: number) {
    this.loadingFinish = true
    this.finishedIds.push(id)
    const comp = this.dialogService.openOverlay(ConfirmDialogComponent, '600px');
    comp.instance.data = {
      title: "Terminanfrage ablehnen",
      message: "Wir senden dem Talent eine Mail, dass du dich zeitnah bei ihm meldest, um einen neuen Termin auszumachen.",
      confirmButtonColor: 'warn',
      confirmButtonText: "Ablehnen",
    };

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(dialogResult => {
      this.handleRequestProceeded('denyConsultantAppointmentRequest', "Terminanfrage abgelehnt", dialogResult, id)
      subscription.unsubscribe();
    })
  }

  acceptRequest(id: number) {
    this.loadingFinish = true
    this.finishedIds.push(id)
    const comp = this.dialogService.openOverlay(ConfirmDialogComponent, '600px');
    comp.instance.data = {
      title: "Terminanfrage annehmen",
      message: "Wir senden dem Talent eine Bestätigungsmail für euren Termin! Außerdem wird der Termin in ADAPT angelegt.",
      confirmButtonColor: 'primary',
      confirmButtonText: "Annehmen",
    };

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(dialogResult => {
      this.handleRequestProceeded('acceptConsultantAppointmentRequest', "Terminanfrage angenommen", dialogResult, id)
      subscription.unsubscribe();
    })
  }


}
