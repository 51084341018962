<form style="min-height:90%" class="content">
    <div class="flex column gap filter-form-inner-container">
        <section class="flex column">
            <div class="flex row space-between-center">

                <div class="flex row start-center">
                    <h2 class="filter-header margin-right">Arbeitsort</h2>
                    <div *ngIf="selectedLocationIds.length > 0" class="filter-count">{{selectedLocationIds.length}}</div>
                </div>
            </div>
            <location-dropdown-content
            [locationIds]="searchService.searchFilterData.positionLocationIds"
            (nationWideEmitter)="nationWideChange($event)"
            (locationIdsChange)="updateLocations($event)"
            ></location-dropdown-content>
        </section>

        <section class="flex column">
            <h3 class="filter-header">Home-Office-Präferenzen</h3>
            <div class="flex row-wrap-gap-sm gap-sm">
                <app-filter-selectable-chip *ngFor="let type of homeOfficeType"
                                            [value]="selectedHomeOfficeTypes.includes(type)"
                                            [text]="type | homeOffice"
                                            (click)="changeHomeOfficeType(type)">
                </app-filter-selectable-chip>
                <app-filter-selectable-chip [value]="selectedHomeOfficeTypes.length == 0"
                                            [text]="'Egal'"
                                            (click)="changeHomeOfficeType(null)"></app-filter-selectable-chip>
            </div>
        </section>
    </div>
</form>
<div class="flex row gap width-100 offset-padding-horizontal filter-sticky-ctas">
    <button (click)="clearFilterAndSearch()"  class="light-utility-button width-50">
        <span>Filter zurücksetzen</span>
    </button>
    <button (click)="applyFilterAndSearch()" class="primary-utility-button width-50">
        <span>Filter anwenden</span>
    </button>
</div>
