<div (click)="openPositionDetails(position.id)"
     *ngIf="position"
     [ngClass]="{
     'selected-box': positionsService?.selectedPositionId?.value == position.id && !position.topjobSlotData,
     'transparent-container': positionsService?.selectedPositionId?.value != position.id && !position.topjobSlotData,
     'light-teal-container': positionsService?.selectedPositionId?.value == position.id && position.topjobSlotData,
     'very-light-teal-container': positionsService?.selectedPositionId?.value != position.id && position.topjobSlotData,
     'selected-border': positionsService?.selectedPositionId?.value == position.id && position.topjobSlotData
     }">
<div  class="flex column gap-sm">

    <div *ngIf="position?.topjobSlotData" class="topjob-image-container">
        <img class="topjob-image"  [src]="topjobImage" alt="">
        <div class="dark-gradient"></div>
        <div class="flex row-wrap-gap-sm start-center position-info-topjob">

            <div class="flex row-gap-sm start-center">
                <company-picture class="company-image" [apiPositionLogoUrl]="position.companyLogoUrl" [positionId]="position.id"></company-picture>
                <div class="company-name" [innerHtml]="position.company.name | safeHtml"></div>
            </div>

            <div class="divider-vertical"></div>
            <locations-marker
                    [preventClickability]="true"
                    [talentId]="searchService.talentId"
                    [positionId]="position.id"
                    [lightMode]=" true"
                    [textSize]="12"
                    [markerSize]="0"
                    [locationDescription]="position?.locationDescription"
                    [personalizesString]="position?.locationShortDescription"></locations-marker>
        </div>
    </div>

    <div class="flex row space-between-center gap">
        <h1 class="position-title">{{position.title}}</h1>
        <positions-recommend-button [positionId]="position.id" [talentId]="searchService.talentId" [showLabel]="false"></positions-recommend-button>
    </div>

    <div class="flex row-wrap-gap-sm start-center position-info" *ngIf="!position?.topjobSlotData">

        <div class="flex row-gap-sm start-center">
            <company-picture class="company-image" [apiPositionLogoUrl]="position.companyLogoUrl" [positionId]="position.id"></company-picture>
            <div class="company-name" [innerHtml]="position.company.name | safeHtml"></div>
        </div>

        <div class="divider-vertical"></div>
        <locations-marker
                [preventClickability]="true"
                [talentId]="this.searchService.talentId"
                [positionId]="position.id"
                [textSize]="12"
                [markerSize]="0"
                [locationDescription]="position?.locationDescription"
                [personalizesString]="position?.locationShortDescription"></locations-marker>
    </div>

    <app-positions-chips [topjob]="!!position.topjobSlotData" [positionId]="position.id" [talentId]="this.searchService.talentId"></app-positions-chips>
</div>
</div>
