import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationData} from '../../generated/data';
import {TalentProfileDialogComponent} from '../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component';
import {DialogService} from '../utility/side-sheet/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
      private router: Router,
      private dialogService: DialogService
  ) {
  }

  performAppropriateClickAction(notification: NotificationData) {
    switch (notification.type) {
      case 'ConsultantTalentRejectedByCompanyNotification':
        this.openTalentProfileDialog(notification.talentId, 1);
        break;
      case 'ConsultantTalentCvUploadNotification':
        this.openTalentProfileDialog(notification.talentId);
        break;
      case 'ConsultantTalentHiredByCompanyNotification':
        this.openTalentProfileDialog(notification.talentId, 1);
        break;
      case 'ConsultantTalentAssignedNotification':
        this.openTalentProfileDialog(notification.talentId);
        break;
      case 'ConsultantTalentAppliedForPositionNotification':
        this.openTalentProfileDialog(notification.talentId, 1);
        break;
      default: this.openTalentProfileDialog(notification.talentId)
      case 'ConsultantOpenFirstContactTalentsNotification':
        this.router.navigateByUrl('/talents')
        break;
      case 'ConsultantTalentRequestedAppointmentNotification':
        this.router.navigate(['/appointmentRequests'], {queryParams: {tab: 0}});
        break;
    }
  }

  openTalentProfileDialog(talentId: number, tabIndex: number = 0) {
    const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
    comp.instance.talentId = talentId;
    comp.instance.tabIndex = tabIndex;

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      subscription.unsubscribe();
    });
  }

  getActionLabel(notification) {
    if (notification.type == 'ConsultantOpenFirstContactTalentsNotification') return 'Talentübersicht'
    if (notification.type == 'ConsultantTalentRequestedAppointmentNotification') return 'Terminanfragen'

    return 'Talentprofil öffnen';
  }
}
