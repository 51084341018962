import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {LocationResource} from "../../../../../generated/resources";
import {LabelData} from "../../../../../generated/data";

@Component({
  selector: 'filter-city-picker',
  templateUrl: './filter-city-picker.component.html',
  styleUrls: ['./filter-city-picker.component.scss']
})
export class FilterCityPickerComponent implements OnInit {
  @Input() control: FormControl;

  constructor(
    private locationResource: LocationResource,
  ) {}

  ngOnInit() {
    if (!(this.control.value instanceof Array)) this.control.setValue([]);
  }

  cities: LabelData[];
   isLoading = false;

  async searchCities(query: string) {
    this.isLoading = true;
    try {
      const result = await this.locationResource.getCitiesByQueryOrZipCode({
        query,
        page: 0,
        pageSize: 25,
        forcedIds: this.control.value.map((c: LabelData) => c.id),
      });
      this.cities = result.content;
    } finally {
      this.isLoading = false;
    }
  }

  toggleCity(city: LabelData) {
    const cities: LabelData[] = this.control.value;

    if (cities.find(c => c.id === city.id)) {
      this.control.setValue(cities.filter(c => c.id !== city.id));
    } else {
      this.control.setValue([...cities, city]);
    }
  }
}
