import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  constructor(private element: ElementRef) {}

  @Output() clickOutside = new EventEmitter<PointerEvent>();

  @HostListener('document:click', ['$event'])
  private onDocumentClick(event: PointerEvent) {
    if (!(event.target instanceof HTMLElement)) return;

    if (this.element.nativeElement.contains(event.target)) return;

    this.clickOutside.emit(event);
  }
}
