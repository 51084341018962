import {Component, Input} from '@angular/core';
import {SearchService} from "../../search.service";

@Component({
  selector: 'app-positions-searchbar-filter',
  templateUrl: './positions-searchbar-filter.html',
  styleUrls: ['./positions-searchbar-filter.scss']
})
export class PositionsSearchbarFilter {

  @Input() type: 'Location' | 'Profession' | 'Other' = 'Location'
  @Input() disabled: boolean = false;

  private _opened: boolean = false;
  get opened() {
    return this._opened && !this.disabled;
  }
  set opened(value) {
    this._opened = value;
  }

  constructor(
      public searchService: SearchService,
  ) {}

  toggleFilterOptions() {
    this.opened = !this.opened;
  }

  onClickOutside() {
    this.opened = false;
  }
}
