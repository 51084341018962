import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {ConfirmDialogComponent} from '../dialog-sheets/confirm-dialog/confirm-dialog.component';
import {LoadingButtonComponent} from './loading-button/loading-button.component';
import {PasswordInputFieldComponent} from './password-input-field/password-input-field.component';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {SideSheetModule} from './side-sheet/side-sheet.module';
import {DialogService} from './side-sheet/dialog.service';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {StatePipe} from './pipe/state.pipe';
import {TalentCardComponent} from './talent-card/talent-card.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TalentFunnelComponent} from './talent-funnel/talent-funnel.component';
import {DialogHeaderComponent} from './dialog-header/dialog-header.component';
import {NotificationsMenuComponent} from './notifications-menu/notifications-menu.component';
import {SliceStringPipe} from './pipe/slice-string.pipe';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ConsultantNotificationPipe} from './pipe/consultant-notification.pipe';
import {EventLogPipe} from './pipe/event-log.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
import {FunnelStateValuePipe} from './pipe/funnel-state-value.pipe';
import {
    NewHiringCelebrationDialogComponent
} from './new-hiring-celebration-dialog/new-hiring-celebration-dialog.component';
import {ApplicationStatePipe} from './pipe/application-state.pipe';
import {RelationStatePipe} from './pipe/relation-state.pipe';
import {PartnerLogoComponent} from './partner-logo/partner-logo.component';
import {DateTimeSelectComponent} from './date-time-select/date-time-select.component';
import {MatDialogModule} from "@angular/material/dialog";
import {
    RecommendMlpPositionButtonComponent
} from "./recommend-mlp-position-button/recommend-mlp-position-button.component";
import {LoadingButtonDirective} from "./directives/loading-button.directive";
import {ChatComponent} from "../chat-overview/chat/chat.component";
import {MatDividerModule} from "@angular/material/divider";
import {ChatMessageComponent} from "../chat-overview/chat/chat-message/chat-message.component";
import {ChatMinimalComponent} from "../chat-overview/chat-minimal/chat-minimal.component";
import {ActiveMarkerComponent} from './active-marker/active-marker.component';
import {CoachingOffersComponent} from './coaching-offers/coaching-offers.component';
import {CompanyPictureComponent} from "./company-picture/company-picture.component";
import {LocationsMarkerComponent} from "./locations-marker/locations-marker.component";
import {HomeOfficePipe} from "./pipe/home-office.pipe";
import {WorkTypePipe} from "./pipe/work-type.pipe";
import {ChipListComponent} from "./chip-list/chip-list.component";
import {ChipComponent} from "./chip/chip.component";
import {ClickOutsideDirective} from "./directives/click-outside.directive";


@NgModule({
    declarations: [
        LoadingButtonDirective,
        ConfirmDialogComponent,
        PasswordInputFieldComponent,
        LoadingButtonComponent,
        StatePipe,
        TalentCardComponent,
        TalentFunnelComponent,
        DialogHeaderComponent,
        NotificationsMenuComponent,
        SliceStringPipe,
        LoadingSpinnerComponent,
        ConsultantNotificationPipe,
        EventLogPipe,
        SafeHtmlPipe,
        FunnelStateValuePipe,
        NewHiringCelebrationDialogComponent,
        ApplicationStatePipe,
        RelationStatePipe,
        PartnerLogoComponent,
        DateTimeSelectComponent,
        RecommendMlpPositionButtonComponent,
        ChatComponent,
        ChatMessageComponent,
        ChatMinimalComponent,
        ActiveMarkerComponent,
        CoachingOffersComponent,
        CompanyPictureComponent,
        LocationsMarkerComponent,
        HomeOfficePipe,
        WorkTypePipe,
        ChipListComponent,
        ChipComponent,
        ClickOutsideDirective,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        SideSheetModule,
        FlexModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
    ],
    exports: [
        PasswordInputFieldComponent,
        LoadingButtonComponent,
        ConfirmDialogComponent,
        LoadingButtonDirective,
        MatButtonModule,
        MatIconModule,
        SideSheetModule,
        FlexModule,
        FormsModule,
        MatFormFieldModule,
        StatePipe,
        SafeHtmlPipe,
        TalentCardComponent,
        TalentFunnelComponent,
        DialogHeaderComponent,
        LoadingSpinnerComponent,
        NotificationsMenuComponent,
        MatProgressSpinnerModule,
        ConsultantNotificationPipe,
        EventLogPipe,
        MatDatepickerModule,
        FunnelStateValuePipe,
        SliceStringPipe,
        ApplicationStatePipe,
        RelationStatePipe,
        PartnerLogoComponent,
        DateTimeSelectComponent,
        RecommendMlpPositionButtonComponent,
        ChatComponent,
        ChatMessageComponent,
        ChatMinimalComponent,
        ActiveMarkerComponent,
        CoachingOffersComponent,
        CompanyPictureComponent,
        LocationsMarkerComponent,
        HomeOfficePipe,
        WorkTypePipe,
        ChipListComponent,
        ChipComponent,
        ClickOutsideDirective,
        MatTooltipModule,
    ],
    providers: [
        DialogService,
        DatePipe,
        MatDatepickerModule,
        MatButton,
    ]
})
export class UtilityModule {
}
