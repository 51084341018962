import {Component, Input, OnInit} from '@angular/core';
import {CityData} from "../../../generated/data";
import {ConsultantEmploymentPositionResource} from "../../../generated/resources";

@Component({
    selector: 'locations-marker',
    templateUrl: './locations-marker.component.html',
    styleUrls: ['./locations-marker.component.scss']
})
export class LocationsMarkerComponent implements OnInit {

    isModeSpecificCities: boolean = false
   loadingPersonalizesString: boolean = true
    locations: CityData[] = []

    @Input() personalizesString: string | null = null
    @Input() locationDescription: string | null = null
    @Input() talentId: number
    @Input() positionId: number
    @Input() markerSize: number = 16
    @Input() textSize: number =14
    @Input() lightMode: boolean = false
    @Input() preventClickability: boolean = false

    constructor(
        private employmentPositionResource: ConsultantEmploymentPositionResource,
    ) {}

    ngOnInit() {
        this.isModeSpecificCities = !['Deutschlandweit', 'Weltweit'].includes(this.locationDescription)
        this.getPersonalizedLocationString()
        this.getLocationsForPosition()
    }

    getPersonalizedLocationString() {
        this.loadingPersonalizesString = true
        this.employmentPositionResource.getPersonalizedLocationForTalent({talentId: this.talentId, positionId: this.positionId}).then(result => {
            if (result?.length > 0) {
                this.personalizesString = result
            }
            this.loadingPersonalizesString = false
            }
        )
    }

    getLocationsForPosition() {
        this.employmentPositionResource.getLocationsForTalent({talentId: this.talentId, positionId: this.positionId}).then(result => {
            this.locations = result
        })
    }

    getToolTipText():string {
        if (this.locations?.length === 1) return ""
        return this.locations.map(l => l.name).join(', ');
    }

}
