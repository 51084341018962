<div class="chip"
     [ngClass]="{
     'light-teal-chip': chip.type == 'Usp' && !mobileDetailUsps,
     'mobile-detail-usp-chip': chip.type == 'Usp' && mobileDetailUsps,
     'dark-teal-chip': chip.type == 'Origin' && chip.title == 'Schnellbewerbung' && !mobileDetailUsps,
     'primary-usp-chip': chip.type == 'Origin' && chip.title == 'Schnellbewerbung' && mobileDetailUsps
     }">
    <div class="mail-icon-wrapper" *ngIf="chip.type == 'Origin' && chip.title == 'Schnellbewerbung'">
        <i class="mdi mdi-email-fast"></i>
    </div>
    {{ chip.title }}
</div>
