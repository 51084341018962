import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {TalentPositionInteractionOrigin, TalentPositionPreviewData} from "../../../generated/data";
import {PositionsService} from "../positions.service";

@Component({
    selector: 'app-positions-previews',
    templateUrl: './positions-previews.component.html',
    styleUrls: ['./positions-previews.component.scss']
})
export class PositionsPreviewsComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() positions: TalentPositionPreviewData[]
    @Input() loading: boolean
    @Input() totalPages: number
    @Input() page: number
    @Input() origin: TalentPositionInteractionOrigin
    @Output() paginatorNextIndex: EventEmitter<number> = new EventEmitter<number>()
    @ViewChild('previews', {static: true}) previews!: ElementRef;

    topDistancePreviews: number = 0;

    updateHeightBound = this.updateHeight.bind(this)

    constructor(
        public positionsService: PositionsService,
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        window.addEventListener('scroll', this.updateHeightBound);
        this.updateHeight();
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.updateHeightBound);
    }

    updateHeight() {
        const previews = document.getElementById('previews');
        if (!previews || this.loading) return
        this.topDistancePreviews = previews.getBoundingClientRect().top;
        if (this.topDistancePreviews > 260) {
            this.topDistancePreviews = 260;
        }
        if (this.topDistancePreviews >= 0) previews.style.height = `calc(100dvh - ${this.topDistancePreviews}px)`;

    }
}
