import {Component, Input, OnInit} from '@angular/core';
import {PositionChipData} from "../../../generated/data";
import {ConsultantEmploymentPositionResource} from "../../../generated/resources";

@Component({
  selector: 'app-positions-chips',
  templateUrl: './positions-chips.component.html',
  styleUrls: ['./positions-chips.component.scss']
})
export class PositionsChipsComponent implements OnInit {

  chips: PositionChipData[] = []

  loading: boolean = false

  @Input() talentId: number;
  @Input() positionId: number
  @Input() topjob : boolean = false

  constructor(
      private consultantEmploymentPositionResource: ConsultantEmploymentPositionResource,
  ) { }

  ngOnInit(): void {
    this.loadChips()
  }

  loadChips() {
    this.loading = true
      this.consultantEmploymentPositionResource.getEmploymentPositionChipsById(
        {positionId: this.positionId, talentId: this.talentId}
      ).then(result => {
          this.chips = result
          this.loading = false
      })
  }

}
