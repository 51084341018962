import {Component, Input, OnInit} from '@angular/core';
import {HomeOffice, PositionChipData} from "../../../../generated/data";
import {HomeOfficePipe} from "../../../utility/pipe/home-office.pipe";

@Component({
  selector: 'app-positions-single-chip',
  templateUrl: './positions-single-chip.component.html',
  styleUrls: ['./positions-single-chip.component.scss'],
})
export class PositionsSingleChipComponent implements OnInit {

  @Input() chip: PositionChipData
    @Input() topjob: boolean = false

  constructor(
      private homeOfficePipe: HomeOfficePipe,
  ) { }

  ngOnInit(): void {
  }

  getText(chip: PositionChipData) {
      if (chip.type == "HomeOffice") {
          return this.homeOfficePipe.transform(chip.title as HomeOffice)
      }
      else if (chip.type == "WorkingHours") {
          return chip.title.replace(" Wochenstunden", "h")
      }
      else return chip.title
  }

}
