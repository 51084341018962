<div class="flex row-wrap">
    <div class="chip" *ngIf="getWeeklyHoursText()">
        <i class="mdi mdi-clock-outline"></i>
        <span>{{getWeeklyHoursText()}}</span>
    </div>
    <div class="chip" *ngIf="position.employmentCategory" >
        <i class="mdi mdi-file-document-edit-outline"></i>
        <span>{{position.employmentCategory | workType}}</span>
    </div>
    <div class="chip" *ngIf="position.homeOffice">
        <i class="mdi mdi-home-account" ></i>
        <span>{{position.homeOffice | homeOffice}}</span>
    </div>
    <div class="chip" *ngIf="getStartingDateText()">
        <i class="mdi mdi-calendar-month"></i>
        <span>{{getStartingDateText()}}</span>
    </div>
</div>
