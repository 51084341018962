import {Component} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";

@Component({
  selector: 'app-position-search-explanation-dialog',
  templateUrl: './position-search-explanation-dialog.component.html',
  styleUrls: ['./position-search-explanation-dialog.component.scss']
})
export class PositionSearchExplanationDialogComponent {
  constructor(
    public sideSheetRef: SideSheetRef,
  ) {}

  close(dontShowAgain: boolean) {
    this.sideSheetRef.sheetClosed.next(dontShowAgain);
  }
}
