import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent<T extends Record<keyof unknown, unknown>> {
  @Input() item: T;
  @Input() displayProperty: keyof T;
  @Output() onRemove = new EventEmitter<T>();

  getDisplayString(item: T) {
    return item[this.displayProperty];
  }
}
