<div class="flex column gap padding width-100">
    <div class="flex column width-100">
        <position-searchbar
                [disabled]="onlyRecommendationHistory"
                [matTooltip]="onlyRecommendationHistory ? 'Die Suchleiste kann nur verwendet werden wenn „Nur bereits empfohlene Stellen“ ausgeschaltet ist' : null"
        ></position-searchbar>
    </div>

    <div class="flex row-wrap start-center">
        <app-positions-searchbar-filter
                [type]="'Location'"
                [disabled]="onlyRecommendationHistory"
                [matTooltip]="onlyRecommendationHistory ? 'Filteroptionen können nur verwendet werden wenn „Nur bereits empfohlene Stellen“ ausgeschaltet ist' : null"
        ></app-positions-searchbar-filter>
        <app-positions-searchbar-filter
                [type]="'Profession'"
                [disabled]="onlyRecommendationHistory"
                [matTooltip]="onlyRecommendationHistory ? 'Filteroptionen können nur verwendet werden wenn „Nur bereits empfohlene Stellen“ ausgeschaltet ist' : null"
        ></app-positions-searchbar-filter>
        <app-positions-searchbar-filter
                [type]="'Other'"
                [disabled]="onlyRecommendationHistory"
                [matTooltip]="onlyRecommendationHistory ? 'Filteroptionen können nur verwendet werden wenn „Nur bereits empfohlene Stellen“ ausgeschaltet ist' : null"
        ></app-positions-searchbar-filter>
        <app-recommended-only-filter></app-recommended-only-filter>
    </div>
</div>
