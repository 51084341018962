import {ComponentRef, Injectable} from '@angular/core';
import {
  RecommendPositionDialogComponent
} from "../dialog-sheets/recommend-position-dialog/recommend-position-dialog.component";
import {ConsultantTalentResource} from "../../generated/resources";
import {DialogService} from "../utility/side-sheet/dialog.service";
import {ConsultantTalentPositionData} from "../../generated/data";

@Injectable({
  providedIn: 'root',
})
export class PositionsRecommendationService {
  constructor(
    private consultantTalentResource: ConsultantTalentResource,
    private dialogService: DialogService,
  ) {}

  private data = new Map<number, Map<number, ConsultantTalentPositionData>>();

  async loadRecommendationData(talentId: number, positionId: number) {
    const position = await this.consultantTalentResource.getTalentPosition({talentId: talentId, positionId: positionId});

    if (typeof position.recommendationDate === 'string') {
      position.recommendationDate = new Date(position.recommendationDate);
    }

    this.set(talentId, positionId, position);
  }

  async recommend(talentId: number, positionId: number) {
    const position = this.get(talentId, positionId);

    const comp: ComponentRef<RecommendPositionDialogComponent> = this.dialogService.openOverlay(RecommendPositionDialogComponent, '600px');
    comp.instance.position = position;
    comp.instance.talentId = talentId;
    const subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(async result => {
      if (result == true) {
        await this.loadRecommendationData(talentId, positionId);
      }
      subscription.unsubscribe();
    });
  }

  get(talentId: number, positionId: number): ConsultantTalentPositionData | undefined {
    return this.data.get(talentId)?.get(positionId);
  }

  private set(talentId: number, positionId: number, data: ConsultantTalentPositionData) {
    if (!this.data.has(talentId)) this.data.set(talentId, new Map<number, ConsultantTalentPositionData>());

    this.data.get(talentId).set(positionId, data);
  }
}
