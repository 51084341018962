import {
  AfterViewChecked,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {
  ConsultantChatResource,
  ConsultantTalentProfilePictureResource,
  ConsultantTalentResource
} from "../../../generated/resources";
import {ChatData, ChatMessageData, ConsultantTalentProfileData} from "../../../generated/data";
import {ConsultantAuthService} from "../../service/consultant.auth.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {TalentProfileDialogComponent} from "../../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {ChatService} from "../../service/chat.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked, OnDestroy {

  profilePictureUrl: string
  consultantId: number
  chatChangeSubscription: Subscription
  today: Date = new Date()

  scrolled: boolean = false
  @Input() chatData: ChatData
  @Input() talent: ConsultantTalentProfileData
  @Input() chatInOverview: boolean
  // @Input() $unseenChatMessages: BehaviorSubject<ChatMessageData[]>

  @Output() newMinimalChat: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('messageContainer') messageContainer: ElementRef
  @ViewChild('textArea') textArea: ElementRef

  scrollHeight: number
  textAreaHeight: number

  messageControl: FormControl = new FormControl()

  constructor(
      private chatResource: ConsultantChatResource,
      private authService: ConsultantAuthService,
      private talentService: ConsultantTalentResource,
      private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource,
      private dialogService: DialogService,
      public chatService: ChatService
  ) { }

  ngOnDestroy(): void {
        this.chatChangeSubscription?.unsubscribe()
    }

  ngAfterViewChecked(): void {
      if(!this.scrolled || this.scrollHeight != this.messageContainer.nativeElement.scrollHeight){
        this.textAreaHeight = this.textArea.nativeElement.offsetHeight
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight
        this.scrollHeight = this.messageContainer.nativeElement.scrollHeight
        this.scrolled = true
      }
      if (this.textArea.nativeElement.offsetHeight != this.textAreaHeight) this.shrinkOrExpandMessageContainer()
  }

  ngOnInit(): void {
    this.scrolled = false
    this.getProfilePicture()
    this.consultantId = this.authService.getAccountInfo().id
  }

  sendMessage(): void {
    if(!this.messageControl.value || this.messageControl.value.trim() == "") return
    this.chatResource.sendChatMessageOrCreateChat(
        this.talent.id,
        {message: this.messageControl.value, chatId: this.chatData.id}
    ).then(() => {
      if (this.chatData.id == null) {
        this.loadChatForCoaching()
        this.newMinimalChat.emit()
      }
    })

    let accountInfo = this.authService.getAccountInfo()
    let newMessage: ChatMessageData = {
      id: null,
      chatId: null,
      text: this.messageControl.value,
      sender: {
        id: accountInfo.id, name: accountInfo.firstName + " " + accountInfo.lastName,
        dtype: 'Consultant', studyName: null
      },
      occurred: new Date(),
      senderFirstName: accountInfo.firstName,
      seenList: []
    }

    this.chatData.messages.push(newMessage)
    this.messageControl.setValue(null)
    this.adjustTextAreaSize()
    this.messageContainer.nativeElement.style.height = this.messageContainer.nativeElement.style.maxHeight
  }

  getProfilePicture(): void {
      if(this.talent && this.talent.profilePictureId) {
        this.consultantTalentProfilePictureResource.getProfileImageHash(this.talent.id).then(result => {
          this.profilePictureUrl = 'url(' + environment.apiUrl + `/consultant/talents/${this.talent.id}/profilePicture/${result})`
        })
      } else {
        this.profilePictureUrl = 'url(../assets/images/blurred_user.png)'
      }
  }

  loadChatForCoaching(): void {
    this.chatResource.getChatForTalent(this.talent.id).then(result => {
      this.chatData = result
    })
  }

  isMessageOldestMessageOfDay(chatMessage: ChatMessageData, index: number): boolean {
    if (index == 0) return true
    let messageDateBefore = new Date(this.chatData.messages[index-1].occurred)
    let messageDate = new Date(chatMessage.occurred)

      return !(messageDateBefore.getDate() == messageDate.getDate() &&
      messageDateBefore.getMonth() == messageDate.getMonth() &&
      messageDateBefore.getFullYear() == messageDate.getFullYear())
  }

  checkIfDateIsToday(date: Date): string {
    let dateToCheck = new Date(date)

    if (
        dateToCheck.getDate() == this.today.getDate() &&
        dateToCheck.getMonth() == this.today.getMonth() &&
        dateToCheck.getFullYear() == this.today.getFullYear()
    ) return "Heute"
    if (
        dateToCheck.getDate() == this.today.getDate() - 1 &&
        dateToCheck.getMonth() == this.today.getMonth() &&
        dateToCheck.getFullYear() == this.today.getFullYear()
    ) return "Gestern"
    return null
  }

  viewTalentProfile(): void {
    const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
    comp.instance.talentId = this.talent.id;
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      subscription.unsubscribe();
    });
  }

  onKeyup(event: KeyboardEvent): void {
    if(event.key === "Enter" && !(event.shiftKey)) this.sendMessage()
    this.adjustTextAreaSize()
  }

  adjustTextAreaSize(): void {
    this.textArea.nativeElement.style.height = "4rem"
    this.textArea.nativeElement.style.height = this.textArea.nativeElement.scrollHeight + "px"
  }

  shrinkOrExpandMessageContainer(): void {
    let heightDifference: number
    if (this.textArea.nativeElement.offsetHeight > this.textAreaHeight) {
      heightDifference = this.textArea.nativeElement.offsetHeight - this.textAreaHeight
      this.messageContainer.nativeElement.style.height =  this.messageContainer.nativeElement.offsetHeight - heightDifference + "px"
      this.textAreaHeight = this.textArea.nativeElement.offsetHeight
    }
    if (this.textArea.nativeElement.offsetHeight < this.textAreaHeight) {
      heightDifference = this.textAreaHeight - this.textArea.nativeElement.offsetHeight
      this.messageContainer.nativeElement.style.height =  this.messageContainer.nativeElement.offsetHeight + heightDifference + "px"
      this.textAreaHeight = this.textArea.nativeElement.offsetHeight
    }
  }

  isPreviousMessageFromTheSameUser(chatMessage: ChatMessageData, index: number): boolean {
    if (index == 0) return true
    let messageBefore = this.chatData.messages[index]
    return messageBefore?.sender.id == chatMessage?.sender.id
  }

}
