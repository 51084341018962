<div class="details-border details-padding flex column gap" *ngIf="!loading && position">


    <section class="flex column gap">
        <h1 class="details-header">
            {{position.content.title}}
        </h1>

        <div class="flex gap-sm position-info row start-center">
            <div class="flex row start-center gap">
                <company-picture
                        [whiteBackground]="true"
                        class="company-image" [apiPositionLogoUrl]="position.companyLogoUrl" [positionId]="position.id"></company-picture>
                <div [innerHtml]="position.company.name | safeHtml"></div>
            </div>
            <div class="divider-vertical"></div>
            <locations-marker [positionId]="position.id"
                              [personalizesString]="position?.locationShortDescription"
                              [locationDescription]="position?.locationDescription"
                              [talentId]="searchService.talentId"></locations-marker>
            <div class="spacer"></div>
            <positions-recommend-button
                    [positionId]="position.id"
                    [talentId]="searchService.talentId"
                    [showLabel]="true"
            ></positions-recommend-button>
        </div>

        <position-usp-chips *ngIf="position.employmentPositionOrigin == 'Uniwunder'"
            [talentId]="searchService.talentId" [positionId]="position.id"
        ></position-usp-chips>
    </section>

    <section
            *ngIf="position?.topJobSlot"
            class="flex row start-center">
        <img
                [ngStyle]="{
                     'filter': 'drop-shadow(0px 0.5rem 1rem ' + position?.secondaryColor + '33)'}"
                class="topjob-contact-image fast-fade-in"  [src]="topJobContactImage" alt="">

        <div
                [ngStyle]="{
                    'background-color': position?.primaryColor + '18'}"
                class="topjob-content-box">
            <div
                    class="company-color-blob"
                    [ngStyle]="{
                    'background-color': position?.primaryColor}"></div>
            <p class="italic-light-text">"{{position?.topJobSlot.content.bannerText}}"</p>
            <p class="bold-text">{{position?.topJobSlot.content.contactName}}</p>
        </div>
    </section>

    <section *ngIf="position.positionShortDescription">
        {{position.positionShortDescription}}
    </section>

    <section class="flex column gap-lg">
        <app-image-gallery [videoUrl]="position.content.videoEmbedLink"
                           [splitId]="position.content.id"
                           [apiImageUrls]="position.content.positionApiImages"
                           [customerImages]="position.content.positionCustomerImages">
        </app-image-gallery>
        <app-positions-details-info-chips [position]="position"></app-positions-details-info-chips>
    </section>

    <section class="flex column gap" *ngIf="position.content.positionDescription">
        <h2 class="section-header">
            Aufgaben
        </h2>
        <div [innerHtml]="position.content.positionDescription | safeHtml"></div>

    </section>

    <section class="flex column gap" *ngIf="position.content.positionRequirements">
        <h2 class="section-header">
            Anforderungen
        </h2>
        <div [innerHTML]="position.content.positionRequirements | safeHtml"></div>

    </section>

    <section class="flex column gap" *ngIf="position.content.benefitsIntro || position.content.benefits?.length">
        <h2 class="section-header">
            Benefits
        </h2>

        <div [innerHtml]="position.content.benefitsIntro"></div>

        <app-positions-details-benefits [benefits]="position.content.benefits"></app-positions-details-benefits>
    </section>
</div>

<app-positions-details-skeleton *ngIf="loading || loadingPreviews?.value"></app-positions-details-skeleton>
