import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {debounceTime} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {LabelData} from "../../../../../generated/data";

@Component({
  selector: 'app-filter-search-input-dropdown',
  templateUrl: './filter-search-input-dropdown.component.html',
  styleUrls: ['./filter-search-input-dropdown.component.scss']
})
export class FilterSearchInputDropdownComponent implements OnInit {

  isFocused: boolean = false;
  @Input() items: LabelData[] = [];
  @Input() query: string = "";
  @Input() placeholder: string = "Benefits und Vorteile suchen";
  @Input() selectedItems: LabelData[] = [];
  @Input() isLoading = false;
  @Output() itemClick: EventEmitter<LabelData> = new EventEmitter<LabelData>();
  @Output() queryChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('scrollContainer') scrollContainer: ElementRef

  readonly queryControl = new FormControl('');

  itemIndex: number | null = null

  ngOnInit(): void {
    this.queryControl.valueChanges.pipe(
        debounceTime(300)  // Adjust the debounce time as needed
    ).subscribe(value => {
      this.reportQueryChange();
    });
  }


  onFocus() {
    this.isFocused = true;
    this.focusIn.emit(true)
    this.itemIndex = null
  }

  onBlur(event: FocusEvent) {
    if (!this.isElementContainer(event.relatedTarget as HTMLElement)) {
      this.isFocused = false;
    }
  }

  preventBlur(event: Event) {
    event.preventDefault();
  }

  onFocusOut(event: FocusEvent) {
    if (!this.isElementContainer(event.relatedTarget as HTMLElement)) {
      this.isFocused = false;
    }
  }

  isElementContainer(element: HTMLElement): boolean {
    return !!element && !!element.closest('.input-container');
  }

  isSelected(item: LabelData): boolean {
    return this.selectedItems.some(i=> i.id == item.id);
  }

  reportClick(item: LabelData) {
    this.itemClick.emit(item);
  }

  reportQueryChange() {
    this.queryChange.emit(this.query);
  }

  selectNextItem() {
    if (this.itemIndex == null) return this.itemIndex = 0
    this.itemIndex += 1

    this.scrollToItem()
  }

  selectPreviousItem() {
    if (this.itemIndex == 0) return
    this.itemIndex -= 1

    this.scrollToItem()
  }

  selectItemByIndex() {
    if (!this.itemIndex || !this.items[this.itemIndex]) this.itemIndex = 0
    if(this.items.length == 0) return
    this.reportClick(this.items[this.itemIndex])
  }

  scrollToItem() {
    if (this.itemIndex !== null && this.scrollContainer) {
      const itemElement = this.scrollContainer.nativeElement.querySelectorAll('.benefit')[this.itemIndex];
      if (itemElement) {
        setTimeout(() => {
          itemElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 100)
      }
    }
  }

}
