import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {PositionsService} from "../positions.service";
import {TalentPositionInteractionOrigin, TalentPositionPreviewData} from "../../../generated/data";

@Component({
  selector: 'app-positions-wrapper-preview-details',
  templateUrl: './positions-wrapper-preview-details.component.html',
  styleUrls: ['./positions-wrapper-preview-details.component.scss']
})
export class PositionsWrapperPreviewDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() positions: TalentPositionPreviewData[]
    @Input() totalPositions: number
    @Input() page: number
    @Input() totalPages: number
    @Input() loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    @Input() origin: TalentPositionInteractionOrigin

    @Output() paginatorNextIndex: EventEmitter<number> = new EventEmitter<number>()

    @ViewChild('detailsContainer', { read: ViewContainerRef, static: false }) detailsContainer: ViewContainerRef;
    @ViewChild('previews') previews: ElementRef;

    loadingSubscription: Subscription = new Subscription();

  constructor(
      public positionsService: PositionsService,
      public router: Router,
  ) {}

  ngAfterViewInit(): void {
      this.loadingSubscription = this.loading.subscribe(() => {
        this.scrollToTop()
      })
  }

  ngOnInit(): void {
      if (!this.positions.some(p => p.id == this.positionsService.selectedPositionId.value)) {
          this.positionsService.selectedPositionId.next(null);
      }
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  scrollToTop() {
    this.previews?.nativeElement?.scrollTo(0, 0)
  }
}
