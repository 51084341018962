<div (clickOutside)="onClickOutside()" [class.unclickable]="disabled">
    <button
            class="light-filter-button"
            [ngClass]="{
            'dark-filter-button':
            (type == 'Profession' && searchService.getFieldFilterCount() > 0) ||
            (type == 'Location' && searchService.getLocationFilterCount() > 0) ||
            (type == 'Other' && searchService.getMoreFilterCount() > 0)
            }" (click)="toggleFilterOptions()">
        <span *ngIf="type == 'Profession'">Berufsfeld</span>
        <span *ngIf="type == 'Location'">Standort</span>
        <span  *ngIf="type == 'Other'">Weitere Filter</span>

        <div *ngIf="type == 'Profession' && searchService.getFieldFilterCount() > 0" class="filter-count">
            {{searchService.getFieldFilterCount()}}
        </div>
        <div *ngIf="type == 'Location' && searchService.getLocationFilterCount() > 0" class="filter-count">
            {{searchService.getLocationFilterCount()}}
        </div>
        <div *ngIf="type == 'Other' && searchService.getMoreFilterCount() > 0" class="filter-count">
            {{searchService.getMoreFilterCount()}}
        </div>

        <i class="mdi mdi-chevron-down" [class.flipped]="opened"></i>
    </button>

    <div class="filter-form-container" [class.open]="opened">
        <app-filter-location-form *ngIf="type == 'Location'" (closeFilter)="opened = false"></app-filter-location-form>
        <app-filter-profession-form
                *ngIf="type == 'Profession'"
                (closeFilter)="opened = false"
        ></app-filter-profession-form>
        <app-filter-other-form *ngIf="type == 'Other'" (closeFilter)="opened = false"></app-filter-other-form>
    </div>
</div>
