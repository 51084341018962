import {Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchService} from "./search.service";
import {PositionsService} from "./positions.service";
import {distinctUntilChanged, filter} from "rxjs/operators";
import {DialogService} from "../utility/side-sheet/dialog.service";
import {
    PositionSearchExplanationDialogComponent
} from "../dialog-sheets/position-search-explanation-dialog/position-search-explanation-dialog.component";

@Component({
    selector: 'app-positions',
    templateUrl: './positions.component.html',
    styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit, OnDestroy {
    @Input() talentId: number;

    @ViewChild('results', {read: ElementRef}) private results: ElementRef;
    @ViewChild('explanation', {read: ElementRef}) private explanation: ElementRef;

    get shouldShowExplanation() {
        return !this.hasDismissedExplanation;
    }

    private hasDismissedExplanation = localStorage.getItem('positions-search.explanation-dismissed') === 'true';

    constructor(
        public searchService: SearchService,
        public positionsService: PositionsService,
        private dialogService: DialogService,
    ) {
        positionsService.selectedPositionId
          .pipe(distinctUntilChanged())
          .pipe(filter(id => !!id))
          .subscribe(() => {
            if (this.shouldShowExplanation) {
                this.explanation?.nativeElement?.scrollIntoView({behavior: 'smooth', block: 'start'});
            } else {
                this.results?.nativeElement?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        });
    }

    async ngOnInit() {
        this.searchService.talentId = this.talentId;

        this.searchService.triggerSearch(this.searchService.page, this.searchService.pageSize);
    }

    showFullExplanation() {
        const dialog: ComponentRef<PositionSearchExplanationDialogComponent> = this.dialogService.openOverlay(PositionSearchExplanationDialogComponent, '500px');

        dialog.instance.sideSheetRef.sheetClosed.subscribe(dontShowAgain => {
            if (dontShowAgain) {
                this.hideExplanation();
            }
        });
    }

    hideExplanation() {
        this.hasDismissedExplanation = true;
        localStorage.setItem('positions-search.explanation-dismissed', 'true');
    }

    ngOnDestroy() {
        this.searchService.talentId = undefined;
    }
}
