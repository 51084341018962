import {Component, Input} from '@angular/core';
import {BenefitData} from "../../../../generated/data";

@Component({
  selector: 'app-positions-details-benefits',
  templateUrl: './positions-details-benefits.component.html',
  styleUrls: ['./positions-details-benefits.component.scss']
})
export class PositionsDetailsBenefitsComponent {
  @Input() benefits: BenefitData[]
}
