import {Component, OnInit} from '@angular/core';
import {ConsultantPerformanceData, ConsultantTalentData} from '../../generated/data';
import {ConsultantControllingResource, ConsultantTalentResource} from '../../generated/resources';
import { RecommendationInfoDialogComponent } from '../dialog-sheets/recommendation-info-dialog/recommendation-info-dialog.component';
import {TalentProfileDialogComponent} from '../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component';
import {DialogService} from '../utility/side-sheet/dialog.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public talentsData: number[];
    public pageSizeOptions = [5, 10, 15, 50];
    public totalElements = 0;
    public page = 0;
    public pageSize = 5;
    public totalPages;
    public loading: boolean = true;
    public activeSlots: boolean = true;
    public performanceData: ConsultantPerformanceData;

    constructor(
        private talentResource: ConsultantTalentResource,
        private dialogService: DialogService,
        private router: Router,
        private consultantControllingResource: ConsultantControllingResource
    ) {
    }

    ngOnInit(): void {
        this.getPerformanceData();
        this.getSlotCreatedInfo()
    }

    getSlotCreatedInfo() {
        this.consultantControllingResource.getSlotCreatedInfo().then(result => {
            this.activeSlots = result
        })
    }

    getPerformanceData() {
        this.consultantControllingResource.getConsultantPerformance().then(result => {
            this.performanceData = result;
            this.loadTalents();
        });
    }

    routeToSlots() {
        this.router.navigate(['/appointmentRequests'], {queryParams: {tab: 1}});
    }

    loadTalents(): void {
        this.talentResource.getNewestTalentsOfConsultant().then(talents => {
            this.talentsData = talents;
            this.loading = false;
        });
    }

    openTalentProfile(talentId: number) {
        const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
        comp.instance.talentId = talentId;

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
            subscription.unsubscribe();
        })
    }

    openRecommendationInfoDialog(){
        const comp = this.dialogService.openOverlay(RecommendationInfoDialogComponent, '600px');

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
            subscription.unsubscribe();
        })
    }
}
