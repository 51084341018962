import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent<T extends Record<keyof unknown, unknown>> {
  @Input() list: T[];
  @Input() displayProperty: keyof T;
  @Output() remove = new EventEmitter<T>();
}
