<div class="details-padding flex column gap">
    <div class="skeleton header"></div>

    <div class="skeleton small-header"></div>

    <div class="padding-vertical">
        <app-positions-chips-skeleton></app-positions-chips-skeleton>
    </div>

    <div class="padding-vertical-lg flex column gap-sm">
        <div class="skeleton text"></div>
        <div class="skeleton text"></div>
        <div class="skeleton text"></div>
        <div class="skeleton text-short"></div>
    </div>

    <div class="skeleton image"></div>
    
    <div class="flex row-wrap">
        <div class="skeleton preview-image"></div>
        <div class="skeleton preview-image"></div>
        <div class="skeleton preview-image"></div>
        <div class="skeleton preview-image"></div>
    </div>

    <div class="padding-vertical-lg">
        <div class="skeleton header"></div>

        <div class="padding-vertical-lg flex column gap-sm">
            <div class="skeleton text"></div>
            <div class="skeleton text"></div>
            <div class="skeleton text"></div>
            <div class="skeleton text-short"></div>
            <div class="skeleton text margin-top"></div>
            <div class="skeleton text"></div>
            <div class="skeleton text-short"></div>
        </div>
    </div>
</div>
