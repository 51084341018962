<div class="flex column gap-sm custom-wrapper-padding" *ngIf="!loading">
    <ng-content select="[before]"></ng-content>

    <div class="flex column gap-sm" *ngFor="let position of positions, index as i, last as isLast">
            <app-positions-preview-card
                    [position]="position"
                    [origin]="origin"
            ></app-positions-preview-card>
        <div class="divider"
             [class.invisible]="isLast || [position.id, positions[i + 1].id].includes(positionsService.selectedPositionId.value)"></div>
    </div>

    <ng-content select="[after]"></ng-content>
</div>
<app-positions-previews-paginator *ngIf="positions?.length && !loading"
                                  [page]="page"
                                  [totalPages]="totalPages"
                                  (pageIndex)="paginatorNextIndex.emit($event)">
</app-positions-previews-paginator>
<mat-spinner *ngIf="loading" diameter="32"></mat-spinner>
