import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-filter-selectable-chip',
  templateUrl: './filter-selectable-chip.component.html',
  styleUrls: ['./filter-selectable-chip.component.scss']
})
export class FilterSelectableChipComponent implements OnInit {

  @Input() value: boolean = false
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Input() text: string = ""

  constructor() { }

  ngOnInit(): void {
  }

  toggleValue() {
    // this.value = !this.value
    // this.valueChange.emit(this.value)
  }

}
