<div style="min-height:90%" class="content">
    <div
            id="filter-other-inner-container"
            class="flex column gap filter-form-inner-container">
        <section
                class="flex column">
            <h2>Sonstiges</h2>
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Arbeitszeit</h3>
                    <div *ngIf="(workingHoursFrom != 5 || workingHoursTo != 48)" class="filter-count">{{this.chosenTimeBenefits.length + 1}}</div>
                    <div *ngIf="(workingHoursFrom == 5 && workingHoursTo == 48) && this.chosenTimeBenefits.length > 0"
                         class="filter-count">{{this.chosenTimeBenefits.length}}</div>
                </div>
            </div>

            <div
                    *ngIf="workTimeExpanded"
                    [ngClass]="{'fast-fade-out': removingWorkTime}"
                    class="flex row start-center gap fast-fade-in inputs margin-bottom">
                <input
                        [value]="workingHoursFrom"
                        (change)="updateWorkingHoursFrom($event)"
                        min="5" [max]="workingHoursTo"
                        class="standard-input" style="width: 4rem" type="number">
                <span>-</span>
                <input
                        [value]="workingHoursTo"
                        (change)="updateWorkingHoursTo($event)"
                        [min]="workingHoursFrom" max="48"
                        class="standard-input" style="width: 4rem" type="number">
                <div>
                    Stunden pro Woche
                </div>
            </div>

            <div
                    *ngIf="workTimeExpanded && !loadingTimeBenefits"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in">
                <button *ngFor="let benefit of timeBenefits"
                        [ngClass]="{'light-selectable-chip': !chosenTimeBenefits.includes(benefit),
                        'dark-selectable-chip': chosenTimeBenefits.includes(benefit)}"
                        (click)="toggleTimeBenefit(benefit)">{{benefit.name}}</button>
            </div>
            <app-positions-chips-skeleton class="margin-bottom-sm" *ngIf="loadingTimeBenefits"></app-positions-chips-skeleton>
            <app-positions-chips-skeleton *ngIf="loadingTimeBenefits"></app-positions-chips-skeleton>

            <div (click)="expandOrMinimizeSection('workTime')"
                 class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="workTimeExpanded"></i>
            </div>
        </section>

        <section class="flex column background-light-grey">
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Anstellungsart</h3>
                    <div *ngIf="selectedEmploymentTypes.length > 0" class="filter-count">{{selectedEmploymentTypes.length}}</div>
                </div>
            </div>

            <div
                    *ngIf="employmentTypesExpanded"
                    [ngClass]="{'fast-fade-out': removingEmploymentTypes}"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in employment-types">
                <button *ngFor="let employmentType of employmentTypes"
                        (click)="changeEmploymentType(employmentType)"
                        [ngClass]="{'light-selectable-chip': !selectedEmploymentTypes.includes(employmentType),
                        'dark-selectable-chip': selectedEmploymentTypes.includes(employmentType)}"
                        class="light-selectable-chip">{{employmentType | workType}}</button>
            </div>
            <div (click)="expandOrMinimizeSection('employmentTypes')" class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="employmentTypesExpanded"></i>
            </div>
        </section>


        <section class="flex column">
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Weitere Benefits und Vorteile</h3>
                    <div *ngIf="chosenBenefits.length > 0" class="filter-count">{{chosenBenefits.length}}</div>
                </div>
            </div>

            <app-filter-search-input-dropdown
                    *ngIf="benefitsExpanded"
                    (focusIn)="scrollToBottom($event)"
                    (queryChange)="loadBenefits($event)"
                    [isLoading]="isLoadingBenefits"
                    [query]="benefitQuery"
                    [items]="benefitsLabelData"
                    (itemClick)="addBenefitAsLabelData($event)"
                    class="fast-fade-in benefits"
                    [selectedItems]="chosenBenefits"
                    [ngClass]="{'fast-fade-out': removingBenefits}">
            </app-filter-search-input-dropdown>

            <div
                    *ngIf="benefitsExpanded"
                    [ngClass]="{'fast-fade-out': removingBenefits}"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in margin-top">
                <button *ngFor="let benefit of chosenBenefits"
                        (click)="removeBenefit(benefit)"
                        class="dark-removeable-chip">
                    {{benefit.name}}
                    <i class="mdi mdi-close"></i>
                </button>
            </div>
            <div
                    (click)="expandOrMinimizeSection('benefits')"
                    class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="benefitsExpanded"></i>
            </div>
        </section>
    </div>
</div>

<div class="flex row gap center width-100 offset-padding-horizontal filter-sticky-ctas">
    <button (click)="clearFilterAndSearch()" class="light-utility-button width-50">
         <span>Filter zurücksetzen</span>
    </button>
    <button (click)="applyFilterAndSearch()" class="primary-utility-button width-50">
         <span>Filter anwenden</span>
    </button>
</div>
