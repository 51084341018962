import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'company-picture',
  templateUrl: './company-picture.component.html',
  styleUrls: ['./company-picture.component.scss']
})
export class CompanyPictureComponent implements OnInit {

  @Input() apiPositionLogoUrl: string
  @Input() positionId: number
  @Input() companyId: number
  @Input() whiteBackground: boolean = false

  companyLogoAvailable: boolean = false;
  companyLogoUrl;

  constructor(
      private sanitizer: DomSanitizer,
      private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loadCompanyLogo()
  }

  loadCompanyLogo() {
    if(this.apiPositionLogoUrl) {
      this.companyLogoUrl = this.apiPositionLogoUrl
      this.companyLogoAvailable = true;
    } else if (this.companyId) {
      this.http.get(environment.apiUrl + `/public/company/${this.companyId}/logo`, {
        headers: new HttpHeaders(),
        responseType: 'blob'
      }).subscribe((result) => {
        this.handleResult(result)
      });
    } else {
      this.http.get(environment.apiUrl + `/public/positions/${this.positionId}/logo`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    }).subscribe((result) => {
      this.handleResult(result)
    });
    }}

  handleResult(blob: Blob) {
    if (blob.size > 0) this.companyLogoAvailable = true;
    this.companyLogoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  }

}
