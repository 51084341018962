<dialog-header dialogHeadline="Stellensuche"></dialog-header>
<div class="content">
    <h2>Stellenempfehlungen suchen</h2>
    <p>
        In der Stellensuche kannst du die Talentagent-Plattform nach passenden Stellen für dein Talent durchsuchen.
        Wenn du dir sicher bist, dass eine der aufgelisteten Stellen zu deinem Talent passt, kannst du diese empfehlen.
        Die Suche funktioniert analog zu der Suche, die dein Talent selbst nutzen kann. Du hast dieselben Filtermöglichkeiten und dir stehen dieselben Stellen zur Verfügung.
    </p>
    <p>
        Gib einfach einen Suchbegriff in die Suchleiste ein und drücke <kbd>Enter</kbd> um die Suche zu starten!
    </p>
    <img src="assets/images/positions-search/search.png" alt width="468" height="166">
    <p>
        Neben der Suchleiste stehen dir weitere Filtermöglichkeiten zur Verfügung um deine Suche weiter zu verfeinern.
        Du kannst beispielsweise nach bestimmten Berufszweigen, Standorten oder Anstellungsarten filtern.
    </p>
    <img src="assets/images/positions-search/filter.png" alt width="468" height="310">
    <p>
        Mit der Filteroption "Nur bereits empfohlene Stellen" kannst du dir die Stellen anzeigen lassen, die du dem Talent bereits empfohlen hast.
    </p>
    <img src="assets/images/positions-search/recommendation-history.png" alt width="468" height="92" class="right">

    <h2>Eine Stelle empfehlen</h2>
    <p>
        Du hast eine passende Stelle für dein Talent gefunden? Super, dann kannst du auf "Empfehlen" klicken, um sie deinem Talent direkt vorzuschlagen.
        Dein Talent erhält dann eine E-Mail und wird so auf deine Stellenempfehlung aufmerksam gemacht!
    </p>
    <img src="assets/images/positions-search/recommend.png" alt width="468" height="200" class="right">
    <p>
        Nachdem du eine Stelle empfohlen hast, wird der "Empfehlen"-Knopf ausgegraut und zeigt nun an, ob sich das Talent auf die Stelle beworben hat, oder ob es kein Interesse hat.
    </p>
    <img src="assets/images/positions-search/recommended.png" alt width="468" height="200" class="right">
</div>
<div class="dialog-action-bar">
    <button mat-button (click)="close(true)">Verstanden, nicht mehr anzeigen</button>
    <button mat-button color="primary" (click)="close(false)">Schließen</button>
</div>
