import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConsultantAuthService} from './service/consultant.auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {ChatData, ChatMinimalData, ConsultantNewHiringData, ConsultantTalentData} from '../generated/data';
import {TalentProfileDialogComponent} from './dialog-sheets/talent-profile-dialog/talent-profile-dialog.component';
import {DialogService} from './utility/side-sheet/dialog.service';
import {ConsultantChatResource, ConsultantNotificationResource, ConsultantTalentResource} from '../generated/resources';
import {debounceTime} from 'rxjs/operators';
import {BehaviorSubject, observable, Observable, Subject, Subscription} from 'rxjs';
import {SupportDialogComponent} from "./dialog-sheets/support-dialog/support-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {NewHiringCelebrationDialogComponent} from "./utility/new-hiring-celebration-dialog/new-hiring-celebration-dialog.component";
import {ChatOverviewComponent} from "./chat-overview/chat-overview.component";
import {TalentSearchbarService} from "./service/talent-searchbar.service";
import {ChatService} from "./service/chat.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  unseenNotificationCount: number = 0;
  unseenChatMessagesCount: number = 0;

  public title: string = 'Talents';

  public talentControl: FormControl = new FormControl();
  public talentsData: ConsultantTalentData[];

  public isExpanded: boolean = false;
  public showSearchResults: boolean = false;
  public inputFocused: boolean = false;
  public showNoItemInfo: boolean = false;
  public loadingTalents: boolean = false;

  public chatsOfConsultant: ChatMinimalData[]

  notificationMenuOpened: Subject<boolean> = new Subject();
  notificationCountIntervalId: number

  chatMessagesCountIntervalId: number

  public page: number = 0;

  $searchbarSubscription: Subscription

  constructor(
      public authService: ConsultantAuthService,
      public router: Router,
      private dialogService: DialogService,
      private talentResource: ConsultantTalentResource,
      private consultantNotificationResource: ConsultantNotificationResource,
      private matDialog: MatDialog,
      private chatResource: ConsultantChatResource,
      private talentSearchbarService: TalentSearchbarService,
      private chatRefreshService: ChatService,
      private activatedRoute: ActivatedRoute,
  ) {
  }


  ngOnInit() {
    this.$searchbarSubscription = this.talentSearchbarService.openSearchbar.subscribe(next => {
      this.showSearchResults = true
      this.searchInput.nativeElement.focus()

    })

    if (!this.authService.isAuthenticated() && location.pathname !== '/login' && location.pathname !== "/onetimetoken/login" ) {
        this.router.navigateByUrl('login');
    }

    this.talentControl.valueChanges
        .subscribe(() => {
          if (this.talentControl.value.toString().length < 1) {
            this.showNoItemInfo = false;
            this.talentsData = [];
          }

          this.loadingTalents = this.talentControl.value.toString().length > 0;
        });

    this.talentControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(next => {
          if (this.talentControl.value.toString().length > 0) {
            this.loadingTalents = true;
            this.page = 0;
            this.getTalentsByQuery();
          }
        });

    this.authService.onLoginSuccess(() => {
      this.getUnseenNotificationCount()
      this.getUnseenChatMessagesCount()
      this.loadChatsOfConsultant()
      this.getNewHirings()
    });

    this.notificationCountIntervalId = setInterval(() => {
      this.getUnseenNotificationCount();
    }, 30000);

    this.notificationMenuOpened.subscribe(next => {
      setTimeout( () => {
        this.getUnseenNotificationCount();
      }, 30000);
    });

    if (this.authService.isAuthenticated()) {
      this.chatMessagesCountIntervalId = setInterval(() => {
        this.getUnseenChatMessagesCount()
      }, 60000)
    }

  }

  ngOnDestroy() {
    clearInterval(this.notificationCountIntervalId)
    this.$searchbarSubscription.unsubscribe()
  }

  openChatOverview(id: number): void {
    this.talentSearchbarService.openChatWithTalentId.next(id)
  }

  openSupportDialog() {
    this.dialogService.openOverlay(SupportDialogComponent, "600px")
  }

  private getUnseenNotificationCountRunning = false;
  getUnseenNotificationCount() {
    if (this.getUnseenNotificationCountRunning) return;
    if (this.authService.isAuthenticated()) {
      this.getUnseenNotificationCountRunning = true;
      this.consultantNotificationResource.getUnseenNotificationCount().then(result => {
        this.unseenNotificationCount = result.value;
      }).finally(() => {
        this.getUnseenNotificationCountRunning = false;
      });
    }
  }

  routeOrRefreshChatMessages() {
    if(this.router.url== "/chat-overview") this.chatRefreshService.refreshChats.next(true)
  }

  getUnseenChatMessagesCount() {
    if (this.authService.isAuthenticated()) {
      this.chatResource.getUnseenChatCount().then(result => {
        this.unseenChatMessagesCount = result.value
      })
    }
  }

  loadChatsOfConsultant(): void {
    this.chatResource.getChatMinimalDataForOverview().then(result => {
      this.chatsOfConsultant = result
      this.chatsOfConsultant.length
    })
  }

  getNewHirings() {
    if (this.authService.isAuthenticated()) {
      this.consultantNotificationResource.getNewHirings().then(result => {
       if(result.length) this.openNewHiringCelebrationDialog(result)
      });
    }
  }

  openNewHiringCelebrationDialog(newHirings: ConsultantNewHiringData[]) {
    this.matDialog.open(NewHiringCelebrationDialogComponent, {
      data: newHirings,
      backdropClass: "new-hirings-celebrations-dialog-backdrop",
      panelClass: "new-hirings-celebrations-dialog-panel",
      autoFocus: false
    })
  }

  showNav() {
    return this.authService.isAuthenticated()
        && this.router.url !== '/login'
        && this.router.url !== '/privacy'
        && this.router.url !== '/onboarding'
        && this.router.url !== '/404'
        && this.router.url !== '/profile/preview';
  }

  getTalentsByQuery() {
    this.talentResource.getTalentsByQuery({q: this.talentControl.value, page: this.page}).then(result => {
      this.showNoItemInfo = result.content.length < 1;
      this.showSearchResults = true;
      this.talentsData = result.content;
      this.loadingTalents = false;
    });
  }

  openTalentProfileDialog(talent) {
    const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
    comp.instance.talentId = talent.id;
  }

  resetSearchInput() {
    this.talentControl.setValue('')
    this.searchInput.nativeElement.focus()
  }

  endFocus() {
    setTimeout(() => {
      this.inputFocused = false
    }, 400)
  }

  logout() {
    this.isExpanded = false;
    this.authService.logout()
  }


}
