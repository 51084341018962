import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TalentPositionInteractionOrigin, TalentPositionPreviewData} from "../../../../generated/data";
import {PositionsService} from "../../positions.service";
import {environment} from "../../../../environments/environment";
import {SearchService} from "../../search.service";

@Component({
  selector: 'app-positions-preview-card',
  templateUrl: './positions-preview-card.component.html',
  styleUrls: ['./positions-preview-card.component.scss']
})
export class PositionsPreviewCardComponent implements OnInit {

  @Input() position: TalentPositionPreviewData
  @Input() origin: TalentPositionInteractionOrigin
  topjobImage: string = ''

  loadingTopjobImage: boolean = true

  constructor(
    public positionsService: PositionsService,
    public searchService: SearchService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    if(this.position && this.position.topjobSlotData){
      this.getTopjobImage()
    }
  }

  openPositionDetails(positionId: number) {
    this.positionsService.openPositionDetails(positionId);
  }

  getTopjobImage() {
    this.getTopJobSlotHeaderImage(this.position.topjobSlotData.content.id)
        .then((blob) => {
          this.topjobImage = this.convertBlobToImageUrl(blob);
          this.loadingTopjobImage = false;
        })
        .catch((error) => {
          console.error('Error loading top job slot header image', error);
          this.loadingTopjobImage = false;
        });
  }

  getTopJobSlotHeaderImage(slotContentId: number): Promise<Blob> {
    const url = environment.apiUrl + `/public/topJobContents/${slotContentId}/headerImage`; // Adjust the URL as needed
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  convertBlobToImageUrl(blob: Blob): string {
    return URL.createObjectURL(blob);
  }
}
