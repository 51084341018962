import {Component, Input} from '@angular/core';
import {BenefitData} from "../../../../../generated/data";

@Component({
  selector: 'app-positions-details-benefit-card',
  templateUrl: './positions-details-benefit-card.component.html',
  styleUrls: ['./positions-details-benefit-card.component.scss']
})
export class PositionsDetailsBenefitCardComponent {
  @Input() benefit: BenefitData
}
