import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ConsultantTalentResource} from "../../generated/resources";

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  readonly selectedPositionId = new BehaviorSubject<number>(null);
  selectedSplitId?: number;

  readonly isOnlyShowingRecommendationHistory = new BehaviorSubject<boolean>(false);

  constructor(
    private talentResource: ConsultantTalentResource,
  ) {}

  async openPositionDetails(positionId: number) {
    this.selectedPositionId.next(positionId);
  }

  onlyShowRecommendationHistory(enabled: boolean) {
    this.isOnlyShowingRecommendationHistory.next(enabled);
  }

  async getRecommendationHistory(talentId: number, page: number, pageSize: number) {
    return await this.talentResource.getTalentRecommendationsFromConsultants(talentId, {page, pageSize});
  }
}
