<div class="flex row start-center">
    <div class="search-input-wrapper-hybrid margin-right" (keyup.enter)="sendKeyword()">
        <input [(ngModel)]="searchString"
               type="text" name="search" [placeholder]="placeholder" [disabled]="disabled">
        <i *ngIf="searchString?.length > 0"
           (click)="searchString = ''"
           class="mdi mdi-close clear-icon clickable"></i>
    </div>
    <button
            class="dark-search-button flex row center search-button"
            style="max-height: 40px;"
            (click)="sendKeyword()"
            [disabled]="disabled"
    >
        <span style="display: inline-block;
              vertical-align: top;
              white-space: nowrap;
              ">Stellen suchen</span>
    </button>
</div>
