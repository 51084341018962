<button
        [disabled]="hasBeenRecommended"
        [matTooltip]="longLabel"
        [class.passive]="hasBeenRecommended"
        [class.small]="!showLabel"
        (click)="recommend()"
>
    <i class="mdi mdi-thumb-up"></i>
    <ng-container *ngIf="showLabel">{{shortLabel}}</ng-container>
</button>
