import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PositionChipData} from "../../../../generated/data";
import {ConsultantEmploymentPositionResource} from "../../../../generated/resources";

@Component({
  selector: 'position-usp-chips',
  templateUrl: './position-usp-chips.component.html',
  styleUrls: ['./position-usp-chips.component.scss']
})
export class PositionUspChipsComponent implements OnInit {

    chips: PositionChipData[] = []

    loading: boolean = false
    splitId?: number = null

    @Input() talentId: number;
    @Input() positionId: number
    @Input() mobileDetailUsps: boolean = false

  constructor(
      private consultantEmploymentPositionResource: ConsultantEmploymentPositionResource,
      private route: ActivatedRoute
  ) {

      this.route.snapshot.queryParams['splitId'] ? this.splitId = parseInt(this.route.snapshot.queryParams['splitId']) : this.splitId = null

  }

  ngOnInit(): void {
        this.loadChips()
    }

    loadChips() {
        this.loading = true
        this.consultantEmploymentPositionResource.getPositionUspsForTalent(
          {talentId: this.talentId, positionId: this.positionId},
          {splitId: this.splitId}
        ).then(result => {
            this.chips = result
            this.loading = false
        })
    }

}
