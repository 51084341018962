<div class="opened-dropdown-wrapper" (click)="$event.stopPropagation()">

    <form   class="radio-group padding-vertical">
        <fieldset id="nationWideGroup">
            <label class="radio-label margin-bottom-sm">
                <input type="radio"
                       [formControl]="wholeGermanyCtr"
                       name="nationWideGroup"
                       value="nationWide"
                       [disabled]="loadingLocations"
                       [checked]="wholeGermanyCtr.value == 'nationWide'">
                <span class="custom-radio"></span>
                <span class="color-regular">Deutschlandweit suchen</span>
            </label>
            <label class="radio-label">
                <input type="radio"
                       [formControl]="wholeGermanyCtr"
                       name="nationWideGroup"
                       value="specificCities"
                       [disabled]="loadingLocations"
                       [checked]="wholeGermanyCtr.value == 'specificCities'">
                <span class="custom-radio"></span>
                <span class="color-regular">In bestimmten Städten suchen</span>
            </label>
        </fieldset>
    </form>

    <ng-container *ngIf="wholeGermanyCtr.value != 'nationWide'">
        <filter-city-picker [control]="citiesCtr"></filter-city-picker>

        <div class="small-text">
            Wir suchen in einem <b class="small-text">Umkreis von 50km</b> von den eingegebenen Städten
        </div>

        <div *ngIf="!loadingLocations" class="section max-height-section"
             [class.padding-vertical]="citiesCtr.value?.length"
        >
                <chip-list *ngIf="citiesCtr.value?.length" [list]="citiesCtr.value" displayProperty="name" (remove)="removePreferredCity($event)"></chip-list>
        </div>
        <div style="overflow: hidden; padding: 0.5rem 0;" *ngIf="loadingLocations">
            <app-positions-chips-skeleton></app-positions-chips-skeleton>
        </div>
    </ng-container>
</div>
