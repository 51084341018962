import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HomeOffice} from "../../../../../generated/data";
import {SearchService} from "../../../search.service";

@Component({
  selector: 'app-filter-location-form',
  templateUrl: './filter-location-form.component.html',
  styleUrls: ['./filter-location-form.component.scss']
})
export class FilterLocationFormComponent implements OnInit {

  @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>()

  homeOfficeType: HomeOffice[] = [
      'HomeOfficeAvailable',
      'OnlyOffice',
      'OnlyRemote'
  ]

  selectedLocationIds: number[] = []
  selectedHomeOfficeTypes: HomeOffice[] = []

  constructor(
      public searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.selectedLocationIds  = this.searchService.searchFilterData.positionLocationIds
    this.selectedHomeOfficeTypes = this.searchService.searchFilterData.homeOffice
  }

  changeHomeOfficeType(type: HomeOffice | null) {
    if (type == null) {
      this.selectedHomeOfficeTypes = []
      return
    }

    if (this.selectedHomeOfficeTypes.includes(type)) {
      this.selectedHomeOfficeTypes = this.selectedHomeOfficeTypes.filter(t => t !== type)
      return
    } else {
      this.selectedHomeOfficeTypes.push(type)
    }
  }

  updateLocations(locationIds: number[]) {
    this.selectedLocationIds = locationIds
  }

  nationWideChange(nationWide: boolean) {
    if (nationWide) {
      this.selectedLocationIds = []
    }
    else {
      this.selectedLocationIds = this.searchService.searchFilterData.positionLocationIds
    }
  }

  applyFilterAndSearch(){
    this.searchService.searchFilterData.positionLocationIds = this.selectedLocationIds
    this.searchService.searchFilterData.homeOffice = this.selectedHomeOfficeTypes
    this.closeFilter.emit()
    this.searchService.triggerSearch()
  }

  clearFilterAndSearch(){
    this.selectedLocationIds = []
    this.selectedHomeOfficeTypes = []
    this.searchService.searchFilterData.positionLocationIds = []
    this.searchService.searchFilterData.homeOffice = []
    this.closeFilter.emit()
    this.searchService.triggerSearch()
  }
}
