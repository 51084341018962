import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from "../../search.service";

@Component({
  selector: 'position-searchbar',
  templateUrl: './position-search-bar.component.html',
  styleUrls: ['./position-search-bar.component.scss']
})
export class PositionSearchBarComponent implements OnInit {
  @Input() placeholder: string = "Was suchst das Talent? (Position, Branche, Stichwort, ...)"
  @Input() searchString: string = ""
  @Input() disabled: boolean = false;

  constructor(
      private searchService: SearchService,
  ) { }

  ngOnInit(): void {
    this.searchString = this.searchService.searchFilterData.searchQuery
    this.searchService.loading.subscribe(() => {
      this.searchString = this.searchService.searchFilterData.searchQuery
    })
  }

  sendKeyword(){
    if(this.searchService.loading.value){
      return;
    }
    this.searchService.searchFilterData.searchQuery = this.searchString
    this.searchService.triggerSearch()
  }
}
