<div id="positionContainer" class="flex column stretch width-100">
    <button *ngIf="shouldShowExplanation" class="explanation-banner" (click)="showFullExplanation()" #explanation>
        <button mat-icon-button><i class="mdi mdi-information-outline"></i></button>
        <span>Dieser Bereich hat sich verändert! Hier klicken, um die Neuerungen kennenzulernen.</span>
        <button mat-icon-button (click)="hideExplanation()"><i class="mdi mdi-close"></i></button>
    </button>
    <button *ngIf="!shouldShowExplanation" (click)="showFullExplanation()" mat-icon-button class="explanation-icon-button"><i class="mdi mdi-information-outline"></i></button>
    <div class="searchbar-wrapper searchbar flex row center">

        <app-positions-search-options class="width-100 cap-width"></app-positions-search-options>
    </div>

    <app-positions-wrapper-preview-details
            #results
            class="width-100"
            [positions]="searchService.searchPositions"
            [totalPositions]="searchService.totalPositions"
            [page]="searchService.page"
            [totalPages]="searchService.totalPages.value"
            [loading]="searchService.loading"
            (paginatorNextIndex)="searchService.triggerSearch($event)"
            origin="ElasticSearch">
        <ng-container before>
            <h2 *ngIf="searchService.isUnfiltered" class="recommended-info">
                Empfehlungen für das Talent
            </h2>
            <div *ngIf="searchService.searchPositions?.length == 0 && !searchService.loading.value"
                 class="flex column center gap no-jobs-info">
                <i class="mdi mdi-heart-search"></i>
                <div class="text-center">
                    Keine passenden Stellen gefunden
                </div>
                <button (click)="searchService.clearFilterAndSearch()" class="grey-button flex row center gap-sm margin-top">
                    <i class="mdi mdi-filter-off"></i>
                    <span>Filter zurücksetzen</span>
                </button>
            </div>
        </ng-container>
    </app-positions-wrapper-preview-details>
</div>
