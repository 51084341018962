<div style="min-height:90%" class="content">
    <div
            id="filter-profession-inner-container"
            class="flex column gap filter-form-inner-container">
        <section class="flex column">
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h2 class="filter-header margin-right">Berufsfelder</h2>
                    <div *ngIf="selectedProfessions.length > 0" class="filter-count">{{selectedProfessions.length}}</div>
                </div>
            </div>

            <p *ngIf="!selectedProfessions.length">Noch keine Berufsfelder ausgewählt</p>

            <div class="flex row-wrap-gap-sm gap-sm" *ngIf="!selectedProfessionsExpanded && !loadingRelevantProfessions && selectedProfessions.length > 2">
                <div class="dark-removeable-chip fast-fade-in flex center"
                     *ngFor="let profession of selectedProfessions | slice:0:3"
                     [ngClass]="{'fast-fade-out': isRemoving(profession)}"
                     (click)="deselectProfession(profession)"
                >
                    {{profession.name}}
                    <i class="mdi mdi-close"></i>
                </div>

                <div
                        *ngIf="selectedProfessions.length - 3 > 0"
                        class="flex width-100 center clickable" (click)="selectedProfessionsExpanded = true">
                    <p *ngIf="selectedProfessions.length - 3 > 1">{{selectedProfessions.length - 3}} weitere Berufsfelder</p>
                    <p *ngIf="selectedProfessions.length - 3 == 1">1 weiteres Berufsfeld</p>
                </div>
            </div>

            <div
                    *ngIf="selectedProfessionsExpanded || selectedProfessions.length <= 2"
                    class="flex row-wrap-gap-sm gap-sm">
                <div class="dark-removeable-chip fast-fade-in flex center"
                     *ngFor="let selection of selectedProfessions"
                     [ngClass]="{'fast-fade-out': isRemoving(selection)}"
                     (click)="deselectProfession(selection)"
                >
                    {{selection.name}}
                    <i class="mdi mdi-close"></i>
                </div>

                <div (click)="selectedProfessionsExpanded = true" class="flex width-100 center clickable"></div>
            </div>

            <div *ngIf="selectedProfessions.length - 3 > 0"
                 (click)="expandOrMinimizeSection('selected')"
                 class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="selectedProfessionsExpanded"></i>
            </div>

        </section>

        <section class="flex column background-light-grey">
            <div class="flex row space-between-center">
                <h3 class="filter-header">Berufsfelder aus dem Studiengang des Talents</h3>
            </div>

            <div class="flex row-wrap-gap-sm gap-sm" *ngIf="!relevantProfessionsExpanded && !loadingRelevantProfessions && relevantProfessions.length > 2">
                <div class="light-removeable-chip fast-fade-in "
                     *ngFor="let profession of relevantProfessions | slice:0:3"
                     [ngClass]="{'fast-fade-out': isRemoving(profession)}"
                     (click)="selectProfession(profession)"
                >
                    {{profession.name}}
                </div>

                <div class="flex width-100 center">
                    <p *ngIf="relevantProfessions.length - 3 > 1">{{relevantProfessions.length - 3}} weitere Berufsfelder</p>
                    <p *ngIf="relevantProfessions.length - 3 == 1">1 weiteres Berufsfeld</p>
                </div>
            </div>

            <div
                    *ngIf="relevantProfessionsExpanded"
                    class="flex row-wrap-gap-sm gap-sm">
                <div class="light-removeable-chip fast-fade-in"
                     *ngFor="let profession of relevantProfessions"
                     [ngClass]="{'fast-fade-out': isRemoving(profession)}"
                     (click)="selectProfession(profession)"
                >
                    {{profession.name}}
                </div>
                <div *ngIf="loadingRelevantProfessions" class="flex center width-100 padding">
                    <mat-spinner color="accent" diameter="50" ></mat-spinner>
                </div>
                <div *ngIf="relevantProfessions.length == 0 && !loadingRelevantProfessions && selectedProfessions.length == 0" class="flex center width-100">
                    <p class="center-text">Wir konnten keine relevanten Berufsfelder finden. Nutze die Berufsfelder-Suche.</p>
                </div>
            </div>

            <div (click)="expandOrMinimizeSection('relevant')" class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="relevantProfessionsExpanded"></i>
            </div>
        </section>

        <section class="flex column">
            <div  class="flex row space-between-center">
                <h3 class="filter-header margin-right">Weitere Berufsfelder</h3>
            </div>

            <app-filter-search-input-dropdown
                    *ngIf="searchedProfessionsExpanded"
                    (queryChange)="searchForProfessions($event)"
                    (focusIn)="scrollToBottom($event)"
                    [query]="professionQuery"
                    [selectedItems]="selectedProfessions"
                    [items]="searchResultProfessions"
                    (itemClick)="selectProfession($event)"
                    [placeholder]="'Berufsfeld suchen'"
                    [isLoading]="loadingSearch"
            >
            </app-filter-search-input-dropdown>

            <div (click)="expandOrMinimizeSection('searched')" class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down" [class.flipped]="searchedProfessionsExpanded"></i>
            </div>
        </section>
    </div>
</div>
<div class="flex row gap width-100 offset-padding-horizontal filter-sticky-ctas">
    <button (click)="clearSelectedProfessions()" class="light-utility-button width-50">
         <span>Filter zurücksetzen</span>
    </button>
    <button (click)="applySelectedProfessions()" class="primary-utility-button width-50">
         <span>Filter anwenden</span>
    </button>
</div>
