import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PositionsService} from "../positions.service";
import {PublicEmploymentPositionDetailsData, TalentPositionInteractionOrigin} from "../../../generated/data";
import {ConsultantEmploymentPositionResource} from "../../../generated/resources";
import {SearchService} from "../search.service";

@Component({
    selector: 'app-positions-details',
    templateUrl: './positions-details.component.html',
    styleUrls: ['./positions-details.component.scss']
})

export class PositionsDetailsComponent implements OnInit, OnDestroy {
    @Input() positionId: BehaviorSubject<number>;
    @Input() splitId: number | null = null
    @Input() loadingPreviews: BehaviorSubject<boolean>
    @Input() origin?: TalentPositionInteractionOrigin;

    position: PublicEmploymentPositionDetailsData
    loading: boolean = true

    positionIdSubscription: Subscription

    loadingTopjobImage: boolean = true
    topJobContactImage: string = ''

    constructor(
        private positionsService: PositionsService,
        private consultantEmploymentPositionResource: ConsultantEmploymentPositionResource,
        private changeDetection: ChangeDetectorRef,
        private http: HttpClient,
        public searchService: SearchService,
    ) {
    }

    ngOnDestroy(): void {
        this.positionIdSubscription?.unsubscribe()
    }

    ngOnInit(): void {
        this.getPositionDetails()

        this.positionId.subscribe(() => {
           this.getPositionDetails();
        });
    }

    getPositionDetails() {
        if (this.positionId.value) {
            this.loading = true
            this.changeDetection.detectChanges()
            this.consultantEmploymentPositionResource.getPositionForTalent({
                positionId: this.positionId.value,
                talentId: this.searchService.talentId,
            }).then(result => {
                if (this.positionId.value === this.positionsService.selectedPositionId.value) {
                    this.position = result
                    if(this.position?.topJobSlot){
                        this.getTopjobContactImage()
                    }
                    this.loading = false
                }
            })
        } else {
            this.position = null
            this.loading = false
        }

    }

    getTopjobContactImage() {
        this.getTopJobSlotHeaderImage(this.position.topJobSlot.content.id)
            .then((blob) => {
                this.topJobContactImage = this.convertBlobToImageUrl(blob);
                this.loadingTopjobImage = false;
            })
            .catch((error) => {
                console.error('Error loading top job slot header image', error);
                this.loadingTopjobImage = false;
            });
    }

    getTopJobSlotHeaderImage(slotContentId: number): Promise<Blob> {
        const url = environment.apiUrl + `/public/topJobContents/${slotContentId}/contactImage`; // Adjust the URL as needed
        return this.http.get(url, { responseType: 'blob' }).toPromise();
    }

    convertBlobToImageUrl(blob: Blob): string {
        return URL.createObjectURL(blob);
    }
}
