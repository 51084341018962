import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {ConsultantTalentPositionPreviewData, LabelData, PagedData, SearchFilterData} from "../../generated/data";
import {ConsultantPositionSearchResource} from "../../generated/resources";
import {PositionsService} from "./positions.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  talentId?: number;

  pageSize: number = 10
  page: number = 0

  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  totalPositions: number = 0
  totalPages: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  interestingFieldOptions: LabelData[] = []
  selectedField: LabelData = null

  public searchPositions: ConsultantTalentPositionPreviewData[] = []
  public searchFilterData: SearchFilterData = {
    benefits: [],
    employmentCategories: [],
    homeOffice: [],
    positionLocationIds: [],
    professionFieldIds: [],
    searchQuery: null,
    searchRadius: 50,
    sort: 'ProfessionScore',
    workingHourFrom: 5,
    workingHourTo: 48,
    forcedPositionIds: [],
    onlyUnseen: false,
    origins: ["Uniwunder","Campusjaeger","Pluss","JobFeed", "Stepstone"]
  }

  get isUnfiltered() {
    return (
        this.getLocationFilterCount() == 0 &&
        this.getFieldFilterCount() == 0 &&
        this.getMoreFilterCount() == 0 &&
        !this.searchFilterData.searchQuery &&
        !this.positionsService.isOnlyShowingRecommendationHistory
    );
  }


  constructor(
      private positionSearchResource: ConsultantPositionSearchResource,
      private positionsService: PositionsService,
      private router: Router,
  ) {
    positionsService.isOnlyShowingRecommendationHistory.subscribe(() => {
      this.triggerSearch();
    });
  }

  triggerSearch(page: number = this.page, pageSize: number = this.pageSize) {
    if(this.loading.value) return
    if(!this.searchFilterData.homeOffice) this.searchFilterData.homeOffice = []
    this.page = page
    this.pageSize = pageSize

    if (this.positionsService.isOnlyShowingRecommendationHistory.value) {
      this.loadRecommendationHistory();
    } else {
      this.loadPositions();
    }
  }

  clearFilterAndSearch() {
    if(this.loading.value) return
    this.searchFilterData.positionLocationIds = []
    this.searchFilterData.searchQuery = ''
    this.selectedField = null
    this.searchFilterData.professionFieldIds = this.interestingFieldOptions.map(i => i.id)
    this.searchFilterData.employmentCategories = []
    this.searchFilterData.benefits = []
    this.searchFilterData.homeOffice = []
    this.searchFilterData.workingHourFrom = 5
    this.searchFilterData.workingHourTo = 48
    this.positionsService.onlyShowRecommendationHistory(false);
    this.triggerSearch()
  }

  getLocationFilterCount(): number{
    if (this.positionsService.isOnlyShowingRecommendationHistory.value) return 0;

    return this.searchFilterData.positionLocationIds.length + this.searchFilterData.homeOffice.length
  }

  getFieldFilterCount(): number {
    if (this.positionsService.isOnlyShowingRecommendationHistory.value) return 0;

    return this.searchFilterData.professionFieldIds.length
  }

  getMoreFilterCount(): number {
    if (this.positionsService.isOnlyShowingRecommendationHistory.value) return 0;

    let employmentCategoryCount = this.searchFilterData.employmentCategories?.length ? this.searchFilterData.employmentCategories.length : 0
    let benefits = this.searchFilterData.benefits?.length ? this.searchFilterData.benefits.length : 0
    let workingHours = (this.searchFilterData.workingHourFrom != 5 || this.searchFilterData.workingHourTo != 48) ? 1 : 0
    return benefits + employmentCategoryCount + workingHours
  }

  //private functions
  private loadPositions() {
    if (!this.talentId) return;

    this.loading.next(true)
      let url = this.router.url
    if(this.checkIfResultsContainForcedIds()) this.searchFilterData.forcedPositionIds = []
    this.positionSearchResource.searchPositionsForTalent(this.searchFilterData,
        {pageNum: this.page, pageSize: this.pageSize, talentId: this.talentId})
        .then((result: PagedData<ConsultantTalentPositionPreviewData>) => {
            //not update selected position id page changed since loading start
            if (result.content?.length && url == this.router.url) {
              this.positionsService.selectedPositionId.next(result.content[0].id);
            }
            this.searchPositions = result.content
            this.totalPositions = result.totalElements
            this.totalPages.next(result.totalPages)
            //NOTE -- I romved this if statement. I would defensively start loading matches from the beginning. If this causes performance issues later down the line we can add it back in. if (this.page+1 == this.totalPages.value)
            this.loading.next(false)
    })
  }

  private async loadRecommendationHistory() {
    if (!this.talentId) return;

    this.loading.next(true);

    try {
      const result = await this.positionsService.getRecommendationHistory(this.talentId, this.page, this.pageSize);

      this.searchPositions = result.content;
      this.totalPositions = result.totalElements;
      this.totalPages.next(result.totalPages);

      if (result.totalElements > 0) {
        this.positionsService.selectedPositionId.next(this.searchPositions[0].id);
      }
    } finally {
      this.loading.next(false);
    }
  }

  checkIfResultsContainForcedIds(): boolean{
    if(this.searchFilterData.forcedPositionIds.length == 0) return true
    return this.searchPositions.some(p => this.searchFilterData.forcedPositionIds.includes(p.id))
  }
}
