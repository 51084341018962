import {Component, Input, OnInit} from '@angular/core';
import {PositionsRecommendationService} from "../positions-recommendation.service";
import {StatePipe} from "../../utility/pipe/state.pipe";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'positions-recommend-button',
  templateUrl: './positions-recommend-button.component.html',
  styleUrls: ['./positions-recommend-button.component.scss'],
  providers: [
    StatePipe,
    DatePipe,
  ],
})
export class PositionsRecommendButtonComponent implements OnInit {
  @Input() talentId: number;
  @Input() positionId: number;
  @Input() showLabel: boolean;

  constructor(
    private positionsRecommendationService: PositionsRecommendationService,
    private statePipe: StatePipe,
    private datePipe: DatePipe,
  ) {}

  get position() {
    return this.positionsRecommendationService.get(this.talentId, this.positionId);
  }

  get hasBeenRecommended(): boolean {
    if (!this.position) return false;
    return this.position.recommendationDate instanceof Date;
  }

  get shortLabel() {
    if (!this.hasBeenRecommended) return 'Empfehlen';

    return this.statePipe.transform(this.position.state);
  }

  get longLabel() {
    if (!this.hasBeenRecommended) return 'Stelle Empfehlen';

    return `Die Stelle wurde am ${this.datePipe.transform(this.position.recommendationDate, 'dd.MM.yyyy')} empfohlen`;
  }

  recommend() {
    this.positionsRecommendationService.recommend(this.talentId, this.positionId);
  }

  async ngOnInit() {
    await this.positionsRecommendationService.loadRecommendationData(this.talentId, this.positionId);
  }
}
