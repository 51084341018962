import {Component} from '@angular/core';
import {PositionsService} from "../../../positions.service";

@Component({
  selector: 'app-recommended-only-filter',
  templateUrl: './recommended-only-filter.component.html',
  styleUrls: ['./recommended-only-filter.component.scss']
})
export class RecommendedOnlyFilterComponent {
  constructor(
    public positionsService: PositionsService,
  ) {}

  toggle() {
    this.positionsService.onlyShowRecommendationHistory(!this.positionsService.isOnlyShowingRecommendationHistory.value);
  }
}
