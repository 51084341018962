<div class="flex width-100 column"
     (focusout)="onFocusOut($event)" >
    <input (focus)="onFocus()" (blur)="onBlur($event)" [formControl]="queryControl" [(ngModel)]="query"
           (keydown.arrowDown)="selectNextItem()"
           (keydown.arrowUp)="selectPreviousItem()"
           (keydown.enter)="selectItemByIndex()"
           [placeholder]="placeholder" class="standard-input width-100" type="text">
    <div class="input-container padding" *ngIf="isFocused"
         (mousedown)="preventBlur($event)" #scrollContainer>
        <div *ngFor="let item of items; let index = index"
             class="flex column
                          hover-dark-overlay clickable
                          benefit padding-vertical-sm" (click)="reportClick(item)" [ngClass]="{'dark-overlay': index == this.itemIndex}">
            <div [class.selected]="isSelected(item)" class="margin-bottom-sm flex row">
                <div class="item-name">{{item.name}}</div>
                <i class="mdi mdi-check" *ngIf="isSelected(item)"></i></div>
            <div class="divider"></div>
        </div>
        <div class="width-100 flex center">
            <mat-spinner color="accent" diameter="50" *ngIf="isLoading"></mat-spinner>
        </div>
    </div>
</div>
