<div class="width-100 flex center">
    <div class="flex row stretch content-width flex-1">
        <div class="scrollable-container previews-wrapper flex row" #previews>
            <div class="flex column stretch width-100">
                <app-positions-previews
                        class="width-100"
                        [loading]="loading.value"
                        [origin]="origin"
                        [page]="page"
                        [totalPages]="totalPages"
                        (paginatorNextIndex)="paginatorNextIndex.next($event); scrollToTop()"
                        [positions]="positions">
                    <ng-content select="[before]" before></ng-content>
                    <ng-content select="[after]" after></ng-content>
                </app-positions-previews>
            </div>
        </div>

        <div class="details-wrapper" id="detailsWrapper">
            <app-positions-details [positionId]="positionsService.selectedPositionId" [splitId]="positionsService.selectedSplitId" [origin]="origin"></app-positions-details>
        </div>
    </div>
</div>
