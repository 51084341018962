<div [ngClass]="{
'light-teal-chip': (chip.type == 'Salary' || chip.type == 'WorkingHours' || chip.type == 'HomeOffice'),
'dark-teal-chip': chip.type == 'Origin' && chip.title == 'Schnellbewerbung',
'grey-chip': chip.type == 'Origin' && chip.title != 'Schnellbewerbung',
'primary-chip': chip.type == 'TopMatch',
'highlight-primary-element': chip.type == 'TopMatch',
'extra-light-chip': (chip.type == 'Salary' || chip.type == 'WorkingHours' || chip.type == 'HomeOffice') && topjob
}" class="chip">
    <div class="mail-icon-wrapper" *ngIf="chip.type == 'Origin' && chip.title == 'Schnellbewerbung'" >
        <i class="mdi mdi-email-fast"></i>
    </div>
     {{ getText(chip) }}
</div>
