import {Component, OnInit} from '@angular/core';
import {SearchService} from "../search.service";
import {PositionsService} from "../positions.service";

@Component({
  selector: 'app-positions-search-options',
  templateUrl: './positions-search-options.component.html',
  styleUrls: ['./positions-search-options.component.scss']
})
export class PositionsSearchOptionsComponent implements OnInit {
  constructor(
      private searchService: SearchService,
      private positionsService: PositionsService,
  ) {}

  get onlyRecommendationHistory() {
    return this.positionsService.isOnlyShowingRecommendationHistory.value
  }

  ngOnInit(): void {
  }
}
