import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BenefitData, LabelData, ProfessionFieldPreferenceData} from "../../../../../generated/data";
import {SearchService} from "../../../search.service";
import {ConsultantProfessionFieldsResource, ConsultantTalentResource} from "../../../../../generated/resources";

@Component({
  selector: 'app-filter-profession-form',
  templateUrl: './filter-profession-form.component.html',
  styleUrls: ['./filter-profession-form.component.scss']
})
export class FilterProfessionFormComponent implements OnInit {

  @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>()


  selectedProfessions: LabelData[] = []
  selectedProfessionIds: number[] = []
  relevantProfessions: LabelData[] = []
  studyProfessions: LabelData[] = []
  searchResultProfessions: LabelData[] = []


  loadingSearch = false
  loadingRelevantProfessions = false

  professionQuery: string = ""
  removingProfessions: Set<number> = new Set<number>();

  selectedProfessionsExpanded: boolean = false
  removingSelected: boolean = false
  relevantProfessionsExpanded: boolean = false
  removingRelevant: boolean = false
  searchedProfessionsExpanded: boolean = true
  removingSearched: boolean = false


  selectingProfession: boolean = false


  constructor(
      private professionResource: ConsultantProfessionFieldsResource,
      public searchService: SearchService,
      private consultantTalentResource: ConsultantTalentResource,
  ) {}

  ngOnInit(): void {
    this.selectedProfessionIds = this.searchService.searchFilterData.professionFieldIds
    this.loadRelevantProfessions()
  }

  loadRelevantProfessions(){
    this.loadingRelevantProfessions = true
    this.consultantTalentResource.getRelevantProfessionFieldPreferencesForTalent(this.searchService.talentId)
        .then((result: ProfessionFieldPreferenceData[]) => {
          this.studyProfessions = result.map(p =>({id: p.fieldId, name: p.fieldName}))
          if(this.selectedProfessions.length < this.selectedProfessionIds.length){
            this.professionResource.getProfessionFieldsByIds({ids: this.selectedProfessionIds}).then((result: LabelData[]) => {
              this.selectedProfessions = result
              this.finishLoading()
            })
          }
          else{
              this.finishLoading()
          }
    })
  }
  
  finishLoading(){
    this.updateRelevantProfessions();
    if(this.relevantProfessions.length < 5){
      this.relevantProfessionsExpanded = true
    }
    this.loadingRelevantProfessions = false
    if(this.selectedProfessions.length == 0){
      this.selectedProfessionsExpanded = true
    }
  }

  searchForProfessions(searchString: string) {
    this.loadingSearch = true
    this.professionResource.getFilteredProfessionFields({q: searchString, forceIds: []}).then((result: LabelData[]) => {
      this.searchResultProfessions = result
      this.loadingSearch = false
    })
  }

  deselectProfession(profession: LabelData) {
    this.removingProfessions.add(profession.id);
    setTimeout(() => {
      this.selectedProfessions = this.selectedProfessions.filter(p => p.id !== profession.id)
      if(this.studyProfessions.includes(profession)){
        this.relevantProfessions.push(profession)
      }
        this.removingProfessions.delete(profession.id);
    }, 50)
  }

  setSelectedProfessions(selected: LabelData[]) {
    setTimeout(() => {
      this.selectedProfessions = selected;
      this.updateRelevantProfessions();
    },50)
  }

  updateRelevantProfessions() {
    if (this.studyProfessions.length && this.selectedProfessions.length) {
      this.relevantProfessions = this.studyProfessions.filter(studyProf =>
          !this.selectedProfessions.some(selectedProf => selectedProf.id === studyProf.id)
      );
    } else {
      this.relevantProfessions = this.studyProfessions;
    }
  }


  selectProfession(profession: LabelData) {
    if(this.selectedProfessions.includes(profession) || this.selectingProfession) {
      this.deselectProfession(profession)
      return
    }
    this.selectingProfession = true
    this.removingProfessions.add(profession.id);

    setTimeout(() => {
      this.selectedProfessions.push(profession)
      if(this.relevantProfessions.includes(profession)){
        this.relevantProfessions = this.relevantProfessions.filter(p => p.id !== profession.id)
      }
      this.removingProfessions.delete(profession.id);
      this.selectingProfession = false
    },50)
  }

  isRemoving(profession: LabelData | BenefitData): boolean {
    return this.removingProfessions.has(profession.id);
  }

  clearSelectedProfessions() {
    this.selectedProfessions = []
    this.searchService.searchFilterData.professionFieldIds = []
    this.searchService.triggerSearch()
    this.closeFilter.emit()

  }

  applySelectedProfessions() {
    this.searchService.searchFilterData.professionFieldIds = this.selectedProfessions.map(p => p.id)
    this.searchService.triggerSearch()
    this.closeFilter.emit()

  }

  expandOrMinimizeSection(section: 'selected' | 'relevant' | 'searched') {
    switch (section) {
      case 'selected':
        if(this.selectedProfessionsExpanded) this.removingSelected = true
        setTimeout(() => {
          this.selectedProfessionsExpanded = !this.selectedProfessionsExpanded
          if(!this.selectedProfessionsExpanded) this.removingSelected = false
        }, 50)
        break
      case 'relevant':
        if(this.relevantProfessionsExpanded) this.removingRelevant = true
        setTimeout(() => {
          this.relevantProfessionsExpanded = !this.relevantProfessionsExpanded
          if(!this.relevantProfessionsExpanded) this.removingRelevant = false
        }, 50)
        break
      case 'searched':
        if(this.searchedProfessionsExpanded) this.removingSearched = true
        setTimeout(() => {
          this.searchedProfessionsExpanded = !this.searchedProfessionsExpanded
          if(!this.searchedProfessionsExpanded) this.removingSearched = false
        }, 50)
        break
    }
  }

  deselectAllProfessions(){
    this.selectedProfessions = []
    this.relevantProfessions = this.studyProfessions
    this.updateRelevantProfessions()
  }

  getCurrentProfessions(){
    this.selectedProfessions = this.searchService.searchFilterData.professionFieldIds.map(id => ({id: id, name: ""}))
  }

  scrollToBottom(event: boolean){
    if(!event) return;
    setTimeout(() => {
      const outerWrapper = document.getElementById('filter-profession-inner-container')
      outerWrapper.scrollTo({ behavior: 'smooth', top: outerWrapper.scrollHeight });
    }, 50)
  }
}
